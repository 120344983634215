.sidebar-open {
  overflow: hidden;
}
.p-sidebar-mask.p-component-overlay.p-sidebar-right {
  background: rgba(12, 12, 12, 0.13) !important;
  transition: all 0.5s;
  // pointer-events: none;
  .dark-theme & {
    background: rgba(12, 12, 12, 0.4) !important;
  }
}
.p-sidebar-right {
  .p-sidebar {
    &.sidebar-drawer {
      width: 47rem;
      box-shadow: none;
      border-radius: 0;
      @include bp(sm-max) {
        border-radius: 0;
      }
      &:before {
        position: absolute;
        content: '';
        background-image: url('../../images/top-curve-white.svg');
        width: 5rem;
        height: 5rem;
        background-size: 5rem;
        left: 0;
        top: 0;
        z-index: 3;
        @include bp(sm-max) {
          display: none;
        }
      }
      &:after {
        position: absolute;
        content: '';
        background-image: url('../../images/bottom-curve-white.svg');
        width: 5rem;
        height: 5rem;
        background-size: 5rem;
        left: 0;
        bottom: 0;
        z-index: 3;
        @include bp(sm-max) {
          display: none;
        }
      }
      &.employment-sidebar{
        .p-sidebar-content{
          .form-box-wrapper{
            padding-top: 7rem;
            form{
              height: calc(100vh - 7rem);
            }
          }
          .title-wrapper{
            padding: 2.8rem 3rem 1.5rem;
          }
        }
      }
      @include bp(xxs-max) {
        width: 100%;
      }
      .dark-theme & {
        background-color: var(--dark-boxes);
      }
      .p-sidebar-header {
        display: none;
      }
      .p-sidebar-content {
        padding: 0;
        .title-wrapper {
          padding: 2.8rem 3rem 0.5rem;
          background: transparent;
          position: absolute;
          z-index: 22;
          left: 0;
          right: 0;
          margin: 0;
          top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include bp(sm-max) {
            padding: 2rem 1.5rem 0.5rem;
          }
          .card-title {
            margin-bottom: 0;
            @include bp(sm-max) {
              font-size: 1.8rem;
            }
          }
          .p-sidebar-close {
            width: 4rem;
            height: 4rem;
            background-color: transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            cursor: pointer;
            transition:
              background-color 0.2s,
              color 0.2s,
              border-color 0.2s,
              box-shadow 0.2s;
            &:hover {
              background: var(--rgba1) !important;
            }
            .dark-theme & {
              &:hover {
                background-color: var(--link-hover-bg) !important;
              }
            }
            @include bp(sm-max) {
              width: 2.7rem;
              height: 2.7rem;
            }
            .pi {
              color: var(--text-color);
              font-weight: 700;
              .dark-theme & {
                color: var(--title-dark-text);
              }
              @include bp(sm-max) {
                font-size: 1.4rem;
              }
            }
          }
        }
        .form-box-wrapper {
          padding-top: 6.3rem;
          @include bp(sm-max) {
            padding-top: 5.5rem;
          }
          &.change-password-form{
            .title-wrapper{
              .p-sidebar-close {
                .pi{
                  font-size: 1.6rem;
                }
              }
            }
            .custom-form-group{
              .p-icon-field{
                position: relative;
                input{
                  padding-right: 4.4rem;
                }
                .p-input-icon{
                  position: absolute;
                  right: 1rem;
                  top: 1.2rem;
                  svg{
                    height: auto;
                    width: 1.6rem;
                  }
                }
              }                   
            }
            .form-btn-wrapper{
              button{
                width: auto !important;
              }
            }   
          }
          form {
            padding: 2.3rem 3rem 10rem;
            height: calc(100vh - 6.3rem);
            overflow-y: auto;
            @include bp(sm-max) {
              padding: 1.5rem 1.5rem 8rem;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 0.5rem;
              position: relative;
              right: 0.8rem;
              padding-right: 0.8rem;
            }
            &::-webkit-scrollbar {
              width: 0.6rem;
              height: 0.6rem;
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 0.5rem;
              background-color: var(--gray-400);
              opacity: 0.8;
              max-height: 10rem;
            }
          }
        }
        .form-btn-wrapper {
          padding: 1.5rem 3rem;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background: #fff;
          z-index: 11;
          margin: 2.4rem 0 0;
          @include bp(sm-max) {
            padding: 1.5rem;
            gap: 1rem;
          }
          .dark-theme & {
            background: var(--dark-boxes);
          }
          button {
            padding: 0 1.7rem;
            margin-bottom: 0 !important;
            &.gray-btn {
              background-color: var(--white);
              border: 0.1rem solid var(--light-gray1);
              color: var(--heading-text);
              box-shadow: none !important;
              &:hover {
                background-color: var(--secondary-btn-bg);
              }
              .dark-theme & {
                color: #e4e6f4;
                border-color: #2c2a40;
                &:hover {
                  background-color: var(--secondary-btn-bg);
                  color: var(--surface-a);
                  border-color: var(--secondary-btn-bg);
                }
              }
            }
          }
        }
      }
    }
  }
}
