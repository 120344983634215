.container {
  max-width: 119rem;
  padding: 0 2.5rem;
  margin: 0 auto;
  @include bp(sm-max) {
    padding: 0 1.5rem;
  }
}
h1 {
  font-size: 6rem;
  line-height: 1.4;
  font-weight: 400;
}
h2 {
  font-size: 4.5rem;
  line-height: 1.26;
  font-weight: 400;
  color: #0b2e58;
  @include bp(xxlg-max) {
    font-size: 4.2rem;
  }
  @include bp(lg-max) {
    font-size: 3.8rem;
  }
  @include bp(sm-max) {
    font-size: 3.4rem;
  }
}
h3 {
  font-size: 3rem;
  font-weight: 400;
}
p {
  color: #333;
  line-height: 1.8;
  font-size: 1.6rem;
}
.btn {
  min-width: 10.5rem;
  padding: 0.75rem 1.5rem;
  display: inline-block;
  border: 0.1rem solid #fa8503;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  transition: 0.3s;
  border-radius: 0.2rem;
  cursor: pointer;
}
.yellow-btn {
  background-color: #fa8503;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #333;
    border-color: #fff;
  }
}
.read-more-btn {
  border-color: #ccc;
  color: #333;
  &:hover {
    background-color: #0b2e58;
    color: #fff;
    border-color: #0b2e58;
  }
}
.main-site-wrapper {
  background-color: #fff;
}
.page-banner-section {
  min-height: 46rem;
  box-sizing: border-box;
  padding: 8rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #000, #000);
    opacity: 0.5;
    content: '';
  }
  &.inner-page-banner-section{
    @include bp(sm-max){
      min-height: 36rem;
    }
  }
  .tatvasoft-inner-banner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .banner-content-wrapper {
    color: #fff;
    text-align: center;
    z-index: 2;
    .container {
      max-width: 100%;
    }
    h1 {
      text-transform: uppercase;
      margin: 0 0 2.5rem;
      line-height: 1.4;

      @include bp(llg-max) {
        font-size: 5rem;
      }
      @include bp(lg-max) {
        font-size: 4rem;
      }
      @include bp(md-max) {
        font-size: 3.6rem;
      }
      @include bp(sm-max) {
        font-size: 3.2rem;
        margin: 0 0 2rem;
      }
      // @include bp(xxxs-max){
      //   font-size: 2.6rem;
      // }
    }
    .form-wrapper {
      max-width: 51.7rem;
      margin: 0 auto;
      form {
        display: flex;
        justify-content: center;
        @include bp(xxs-max) {
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        }
        input {
          font-family: 'Public Sans', sans-serif;
          background-color: #fff;
          height: 5rem;
          line-height: 4.8rem;
          padding: 0 1.5rem;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border: 0.1rem solid #fff;
          outline: none;
          box-shadow: none;
          font-size: 1.4rem;
          font-weight: 400;
          color: var(--black2);
          @include bp(sm-max) {
            height: 4.2rem;
            line-height: 4rem;
          }
          @include bp(xxs-max) {
            border-top-right-radius: 0.5rem !important;
            border-bottom-right-radius: 0.5rem !important;
          }
          &:hover {
            border-color: #fff;
          }
          &::placeholder {
            font-family: 'Public Sans', sans-serif;
            opacity: 1 !important;
            color: var(--placeholder-gray);
          }
          ::-webkit-input-placeholder {
            opacity: 1 !important;
            color: var(--placeholder-gray);
          }

          :-moz-placeholder {
            opacity: 1 !important;
            color: var(--placeholder-gray);
          }

          ::-moz-placeholder {
            opacity: 1 !important;
            color: var(--placeholder-gray);
          }

          :-ms-input-placeholder {
            opacity: 1 !important;
            color: var(--placeholder-gray);
          }
        }
        button {
          flex: 0 0 11.7rem;
          max-width: 11.7rem;
          height: 5rem;
          line-height: 5rem;
          font-size: 1.5rem;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          &:hover {
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
          }
          @include bp(sm-max) {
            height: 4.2rem;
            line-height: 4.2rem;
          }
          @include bp(xxs-max) {
            flex: none;
            border-top-left-radius: 0.5rem !important;
            border-bottom-left-radius: 0.5rem !important;
          }
        }
      }
      .has-searchbar {
        width: 100%;
        input {
          border-radius: 5px !important;
        }
        &.p-inputwrapper-filled {
          // input{
          //   border-radius: 5px 5px 0 0 !important;
          // }
        }
      }
    }
  }
}
.warning-msg-block {
  padding-top: 5rem;
  @include bp(sm-max) {
    padding-top: 4rem;
  }
  .container {
    max-width: 134rem;
  }
  .warning-content-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.4rem 3rem 1.4rem 2.5rem;
    border: 0.1rem solid #f4daa6;
    background-color: #fdf8ed;
    border-left-width: 0.5rem;
    border-left-color: #e09600;

    img {
      max-width: 3.4rem;
      height: 2.9rem;
      width: 100%;
    }
    p {
      color: #734c00;
      padding-left: 2rem;
      box-sizing: border-box;
      font-size: 1.7rem;
      line-height: 1.529;
      max-width: calc(100% - 3.4rem);
      flex: 0 0 calc(100% - 3.4rem);
    }
    a {
      color: #156ba3;
      font-size: 1.7rem;
      line-height: 1.529;
      text-decoration: underline;
    }
  }
}
.tatva-career-description {
  padding: 3.9rem 0 3.1rem;
  @include bp(lg-max) {
    padding: 3.9rem 0 0;
  }
  .container {
    max-width: 134rem;
  }
  p {
    border-bottom: 0.1rem solid #eee;
    padding-bottom: 4.7rem;
    font-size: 1.8rem;
    @include bp(lg-max) {
      padding-bottom: 4.2rem;
    }
    @include bp(sm-max) {
      padding-bottom: 3.5rem;
      font-size: 1.6rem;
    }
  }
}

.tatva-job-section {
  padding: 4rem 0 2rem;
  .container {
    max-width: 134rem;
  }
  .title-block {
    margin-bottom: 3.2rem;
    h2 {
      text-align: center;
    }
  }
  .job-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem -3rem;
    @include bp(smd-max) {
      margin: 0 -1.2rem -2.4rem;
    }
    @include bp(ssm-max) {
      margin: 0 0 -1.5rem;
    }
    .job-grid {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 0 1.5rem;
      // margin-bottom: 3rem;
      @include bp(lg-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(smd-max) {
        padding: 0 1.2rem;
        margin-bottom: 2.4rem;
      }
      @include bp(ssm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 1.5rem;
      }
      .p-card {
        box-shadow: unset;
        height: 100%;
        background-color: #f8f8f8;
        .p-card-body {
          padding: 3.5rem 2.9rem 3.4rem;
          text-align: center;
          @include bp(lg-max) {
            padding: 3.5rem 2.5rem;
          }
          @include bp(smd-max) {
            padding: 3rem 2.5rem;
          }
          @include bp(sm-max) {
            padding: 2rem 1.5rem;
          }
          .p-card-content {
            padding: 0;
            em {
              display: inline-flex;
              align-items: center;
              img {
                height: 6rem;
                width: auto;
              }
            }
          }
        }
        h3 {
          margin: 2rem 0;
          font-size: 2.2rem;
          color: #0b2e58;
          font-weight: 600;
          @include bp(lg-max) {
            font-size: 2rem;
          }
          @include bp(sm-max) {
            margin: 1.5rem 0;
          }
        }
        .exp-wrapper {
          margin-bottom: 2.5rem;
          @include bp(sm-max) {
            margin-bottom: 2rem;
          }
          p {
            font-size: 1.6rem;
            color: var(--heading-text);
            span {
              font-weight: 600;
            }
          }
        }
        .btn-wrapper {
          .btn {
            text-transform: uppercase;
            @include bp(xxxs-max) {
              display: block;
            }
            + .btn {
              margin-left: 1.2rem;
              @include bp(xxxs-max) {
                margin-left: 0;
                margin-top: 1rem;
              }
            }
          }
        }
      }
      .yellow-btn {
        &:hover {
          background-color: #0b2e58;
          color: #fff;
          border-color: #0b2e58;
        }
      }
    }
  }
}
.tatva-career-benefit {
  padding: 4rem 0 6rem;

  @include bp(lg-max) {
    padding: 3rem 0 5rem;
  }
  @include bp(sm-max) {
    padding: 2rem 0 4rem;
  }
  .container {
    max-width: 134rem;
  }
  .title-block {
    margin-bottom: 3.2rem;
    text-align: center;
    position: relative;
  }
  .work-grid-wrapper {
    display: flex;
    margin: 0 -1.5rem -3rem;
    flex-wrap: wrap;
    @include bp(smd-max) {
      margin: 0 -1.2rem -2.4rem;
    }
    @include bp(xs-max) {
      margin: 0 0 -1.5rem;
    }
    .work-item {
      max-width: 33.33%;
      flex: 0 0 33.33%;
      padding: 0 1.5rem 3rem;
      @include bp(smd-max) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1.2rem;
        margin-bottom: 2.4rem;
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 1.5rem;
      }
      .p-card {
        background: 0 0;
        border: 0.1rem solid #e1e1e1;
        text-align: center;
        padding: 3.5rem 2.9rem 3.5rem;
        box-shadow: unset;
        height: 100%;
        @include bp(smd-max) {
          padding: 3rem 2.5rem;
        }
        .p-card-body {
          padding: 0;
          .p-card-content {
            padding: 0;
          }
        }
        em {
          margin-bottom: 1.3rem;
          min-height: 5.2rem;
          display: block;
        }
        h3 {
          color: #0b2e58;
          font-size: 2.2rem;
          line-height: 1.35;
          font-weight: 400;
          span {
            display: block;
          }
          @include bp(lg-max) {
            font-size: 2rem;
          }
        }
        p {
          font-size: 1.6rem;
          margin-top: 1.1rem;
          line-height: 1.65;
          @include bp(sm-max) {
            margin-top: 0.6rem;
          }
        }
      }
    }
  }
}
.recruitment-process-section {
  padding: 7rem 0;
  background: #f8f8f8;

  @include bp(lg-max) {
    padding: 5rem 0;
  }
  @include bp(sm-max) {
    padding: 4rem 0;
  }
  .container {
    max-width: 134rem;
  }
  h2 {
    margin-bottom: 2rem;
    text-align: center;
  }
  .recruitment-process-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem -3rem;
    @include bp(lg-max) {
      margin: 0 -1.2rem -2.4rem;
    }
    @include bp(xs-max) {
      margin: 0 0 -1.5rem;
    }
    .recruitment-process-col {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 1.5rem 3rem;

      @include bp(lg-max) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 1.2rem;
        margin-bottom: 2.4rem;
      }

      @include bp(smd-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include bp(xs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 1.5rem;
      }
      &.green-block {
        .p-card {
          h3 {
            color: #64b02a;
            &::before {
              border-top-color: #64b02a;
            }
          }
        }
      }
      &.purple-block {
        .p-card {
          h3 {
            color: #8046aa;
            &::before {
              border-top-color: #8046aa;
            }
          }
        }
      }
      &.orange-block {
        .p-card {
          h3 {
            color: #e67028;
            &::before {
              border-top-color: #e67028;
            }
          }
        }
      }
      .p-card {
        box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 2.5rem 3rem;
        height: 100%;
        .p-card-body {
          padding: 0;
          .p-card-content {
            padding: 0;
          }
        }
        @include bp(sm-max) {
          padding: 2rem 2.5rem;
        }
        @include bp(xs-max) {
          padding: 1.2rem 2rem;
        }
        em {
          margin-bottom: 2.6rem;
          display: block;
          @include bp(lg-max) {
            margin-bottom: 2.2rem;
          }
          @include bp(sm-max) {
            margin-bottom: 1.8rem;
          }
        }
        h3 {
          color: #0fa7a8;
          position: relative;
          font-size: 2.6rem;
          line-height: 1.2;
          &::before {
            position: absolute;
            content: '';
            left: -3.5rem;
            border-top: 1rem solid #0fa7a8;
            border-right: 1rem solid transparent;
            top: 1rem;
            transform: rotate(134deg);
            @include bp(sm-max) {
              left: -3rem;
            }
            @include bp(xs-max) {
              left: -2.5rem;
            }
          }
          @include bp(lg-max) {
            font-size: 2.2rem;
          }
          @include bp(sm-max) {
            font-size: 2rem;
          }
        }
        p {
          margin-top: 1.8rem;
          line-height: 1.65;
          @include bp(lg-max) {
            margin-top: 1.3rem;
          }
          @include bp(sm-max) {
            font-size: 1.6rem;
            margin-top: 0.9rem;
          }
        }
      }
    }
  }
  .note-txt {
    margin-top: 3rem;
  }
}
.career-info-section {
  padding: 4rem 0;
  background-color: #0b2e58;
  @include bp(sm-max) {
    padding: 3rem 0;
  }
  .talented-team-block {
    text-align: left;
    p {
      font-size: 3.2rem;
      line-height: 1.5;
      color: #fff;
      display: inline;
      @include bp(lg-max) {
        font-size: 2.8rem;
      }
      @include bp(sm-max) {
        font-size: 2.4rem;
      }
      a {
        color: #fa8503;
        transition: all 0.3s;
        &:hover {
          text-decoration: underline;
        }
      }
      + p {
        margin-left: 0.1rem;
      }
    }
  }
}
// .cta-block {
//   background-color: #0b2e58;
//   .container {
//     max-width: 119.1rem;
//     .cta-block-inner {
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       justify-content: space-between;
//       padding: 4rem 0 3.9rem;
//       .content-block {
//         h2 {
//           font-size: 3.2rem;
//           color: #fff;
//           padding-right: 5rem;
//         }
//         p {
//           color: #fff;
//           font-size: 2rem;
//           font-weight: 400;
//           a {
//             color: #fa8503;
//             font-weight: 600;
//             display: inline-block;
//           }
//         }
//       }
//       .btn-wrapper {
//         .btn {
//           text-transform: uppercase;
//           min-width: 13.5rem;
//           padding: 1.3rem 3rem;
//         }
//       }
//     }
//   }
// }

i.ui-autocomplete-loader {
  margin: -44px;
}

.more {
  width: 100%;
  padding: 0 1.5rem;
  text-align: right;
  color: #0b2e58;
  text-align:  center;
  margin-top: 20px;
  .yellow-btn{
    &:hover{
      background-color: #0b2e58;
      color: #fff;
      border-color: #0b2e58;
    }
  }
}
