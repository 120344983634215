* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 1.6rem;
}

body {
  font-family: 'Public Sans', sans-serif !important;
  color: #333333 !important;
  background-color: #f2f2f2;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi {
  font-size: 1.6rem;
}

.p-component {
  font-size: 1.4rem;
}

.capitalize {
  text-transform: capitalize;
}

.searchBox{
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .search-icon{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  input{
    flex: 1;
    padding-left: 35px;
    padding-right: 35px;
  }
  .close-icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
