.layout-sidebar {
  position: fixed;
  width: 25rem;
  height: 100vh;
  z-index: 999;
  user-select: none;
  top: 0;
  left: 0;
  transition:
    transform $transitionDuration,
    left $transitionDuration,
    width $transitionDuration;
  background-color: #ffffff !important;
  padding: 0 0 6.9rem;
  border-radius: 0 2.5rem 2.5rem 0;

  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      width: 8rem;
      overflow: hidden;
    }
  }

  .sidebar-inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .layout-menu-container {
    height: 100%;
  }

  .copyright-block {
    position: absolute;
    bottom: 0;
    padding: 0.5rem 2.5rem 1.5rem;
    text-align: left;
    width: 100%;
    font-size: 1.1rem;
    line-height: 1.17;
    color: #8e8d8d !important;
    background-color: #fff;
    .sidebar-hide:not(.sidebar-hover) & {
      @include bp(smd-min-2) {
        padding: 0.5rem 1.5rem 1.5rem;;
        text-align: center;
      }
    }

    .site-name {
      font-size: 1.4rem;
      margin-bottom: 1rem;

      img {
        width: 14rem;
        cursor: pointer;
        max-width: none;
      }

      .sidebar-hide:not(.sidebar-hover) & {
        @include bp(smd-min-2) {
          font-size: 1.2rem;
          width: 4rem;
          overflow: hidden;
          transform: translateX(0.8rem);
        }
      }
    }

    p {
      margin: 0 0 0.3rem;
    }
  }
}

.site-logo-wrap {
  padding: 2rem 2.4rem 2rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sidebar-hide:not(.sidebar-hover) {
    @include bp(smd-min-2) {
      padding: 2rem 1.4rem 2rem 1.5rem;
    }
  }

  @include bp(llg-max) {
    padding: 2rem 2.4rem 1.5rem 2.4rem;

    .sidebar-hide:not(.sidebar-hover) {
      @include bp(smd-min-2) {
        padding: 2rem 1.4rem 1.5rem 1.4rem;
      }
    }
  }

  @include bp(smd-max) {
    display: none;
  }

  .site-logo {
    display: block;
    transition: transform $transitionDuration;

    .sidebar-hide:not(.sidebar-hover) & {
      @include bp(smd-min-2) {
        min-width: 2rem;
        overflow: hidden;
        width: 2rem;
        transform: translateX(-0.3rem);
        margin: 0 auto;
      }
    }

    img {
      margin: 0 auto;
      height: 3.7rem;
      width: 100%;
      object-fit: cover;
      object-position: left;   
    }
  }

  .sidebar-toggle {
    position: relative;
    line-height: 0;
    cursor: pointer;
    margin: -1rem;
    padding: 1rem;

    .sidebar-hide:not(.sidebar-hover) & {
      @include bp(smd-min-2) {
        display: none;
      }
    }

    img {
      transition: all 0.5s;
      width: 1.8rem;
      height: 1.8rem;
    }

    .hide-dot {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s;

      .sidebar-hide & {
        opacity: 1;
      }
    }

    .show-dot {
      .sidebar-hide & {
        opacity: 0;
      }
    }
  }
}

.sidebar-graphic-block {
  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      display: none;
    }
  }

  img {
    max-width: 20rem;
    width: 100%;
    margin: 0 auto;
    display: block;

    @include bp(xxs-max) {
      max-width: 17rem;
    }
  }
}

.p-dialog-content {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.5rem;
    position: relative;
    right: 0.8rem;
    padding-right: 0.8rem;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #bdbdbd !important;
    opacity: 0.8;
    max-height: 10rem;
  }
}

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100vh - 10rem);
  overflow-y: auto;
  padding: 0 2.4rem;
  width: 100%;
  transition: padding $transitionDuration;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.5rem;
    position: relative;
    right: 0.8rem;
    padding-right: 0.8rem;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #bdbdbd !important;
    opacity: 0.8;
    max-height: 10rem;
  }

  @include bp(smd-max) {
    padding: 0 1.5rem;
  }

  @include bp(xxs-max) {
    height: calc(100vh - 13rem);
  }

  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      padding: 0 1.5rem;
    }
  }

  li {
    margin: 0 0 1rem;
    &.active-menuitem {
      > a {
        background: linear-gradient(
          72.47deg,
          rgba(115, 103, 240, 1) 22.16%,
          rgba(115, 103, 240, 0.7) 76.47%
        ) !important;
        color: #ffffff !important;
        opacity: 1;
        &:hover {
          color: #333333 !important;
        }
      }
      ul {
        li {
          a {
            &.router-link-exact-active {
              background: transparent;
              color: #64b5f6 !important;
            }
            &:after {
              background: transparent !important;
            }
          }
        }
      }
    }
    &.layout-menuitem-category {
      margin-top: 0.75rem;
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }

    .layout-menuitem-root-text {
      text-transform: uppercase;
      color: #ffffff !important;
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      display: none;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #333333 !important;
      transition: color $transitionDuration;
      border-radius: 2.6rem;
      padding: 1.1rem 1.3rem;
      transition:
      background-color 0.15s,
      border-radius $transitionDuration;
      width: 100%;
      height: 4.5rem;
      em{
        display: block;        
        position: relative;
        top: -0.1rem;
      }
      .sidebar-hide:not(.sidebar-hover) & {
        @include bp(smd-min-2) {
          position: relative;
          width: 4.5rem;
          padding: 1.1rem 1.3rem;
          text-align: center;

          &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 4.5rem;
            width: 4.5rem;
            border-radius: 50%;
            z-index: -1;
          }
        }
      }

      .pi {
        width: 2rem;
        font-size: 1.6rem;
      }

      span {
        margin-left: 0.8rem;
        display: inline-block;
        line-height: 1;

        .sidebar-hide:not(.sidebar-hover) & {
          @include bp(smd-min-2) {
            display: none;
          }
        }
      }

      .menuitem-toggle-icon {
        margin-left: auto;
        .sidebar-hide:not(.sidebar-hover) & {
          display: none;
        }
      }

      &:focus {
        @include focused-inset();
      }

      &:hover {
        background: rgba(51, 48, 60, 0.04);

        .sidebar-hide:not(.sidebar-hover) & {
          @include bp(smd-min-2) {
            background-color: transparent;

            &::after {
              background: rgba(51, 48, 60, 0.04);
            }
          }
        }
      }

      &.router-link-exact-active {
        background: var(--btn-gradient) !important;
        color: #ffffff !important;
        opacity: 1;
        em {
          img{
            filter: brightness(0) invert(1);
          }
        }
        // .sidebar-hide:not(.sidebar-hover) & {
        //   @include bp(smd-min-2) {
        //     // background: transparent;
        //     background: #0000 !important;
        //     &::after {
        //       background: linear-gradient(
        //         72.47deg,
        //         rgba(115, 103, 240, 1) 22.16%,
        //         rgba(115, 103, 240, 0.7) 76.47%
        //       ) !important;
        //     }
        //   }
        // }
      }

      .p-badge {
        margin-left: auto;
      }
    }

    > a {
      .menuitem-toggle-icon {
        &:before {
          content: '\e902';
          font-size: 1.4rem;
        }
      }
    }

    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          &:before {
            content: '\e903';
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &.layout-submenu-wrapper-enter {
        max-height: 0;
      }

      &.layout-submenu-wrapper-enter-active {
        overflow: hidden;
        max-height: 100rem;
        transition: max-height 1s ease-in-out;
      }

      &.layout-submenu-wrapper-enter-done {
        transform: none;
      }

      &.layout-submenu-wrapper-exit {
        max-height: 100rem;
      }

      &.layout-submenu-wrapper-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
      }

      ul {
        padding-left: 0;
        .sidebar-hide:not(.sidebar-hover) & {
          padding-left: 0;
        }
        li {
          a {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.menu-link {
  display: flex;
}

.p-menu {
  width: 13.5rem;
}

.pi {
  &.custom-ic {
    &::before {
      background-size: 1.5rem;
      background-repeat: no-repeat;
      width: 2rem;
      height: 2rem;
      display: block;
      background-position: center;
    }
  }

  &.technology-ic {
    &::before {
      background-size: 1.7rem;
      content: '';
      background-image: url(../../images/technology.svg);

      .router-link-exact-active & {
        background-image: url(../../images/technology-white.svg);
      }
    }
  }

  &.clock-ic {
    &::before {
      content: '';
      background-image: url(../../images/clock.svg);
      background-size: 1.6rem;

      .router-link-exact-active & {
        background-image: url(../../images/clock-white.svg);
      }
    }
  }

  &.interviews-ic {
    &::before {
      content: '';
      background-image: url(../../images/interviews.svg);

      .router-link-exact-active & {
        background-image: url(../../images/interviews-white.svg);
      }
    }
  }
}

@media (max-height: 700px) and (orientation: landscape) {
  .sidebar-graphic-block {
    margin-top: 1rem;

    img {
      width: 10rem;
    }
  }

  .layout-menu {
    height: calc(100vh - 14.8rem);
  }
}

@media (max-height: 700px) and (max-width: 991px) and (orientation: landscape) {
  .sidebar-graphic-block {
    display: none;
  }

  .layout-menu {
    height: calc(100vh - 12.8rem);
  }
}

.sidebar-curve {
  position: fixed;
  left: 25rem;
  top: 0;
  background-color: #fff !important;
  z-index: 991;
  transition: left $transitionDuration;
  display: none;

  .sidebar-hide:not(.sidebar-hover) & {
    left: 8rem;
  }

  .sidebar-hover & {
    z-index: 999;
  }

  @include bp(smd-max) {
    display: none;
  }

  img {
    display: block;
    width: 5rem;

    @include bp(llg-max) {
      width: 2.4rem;
    }

    &.light-theme-curve {
      display: block;
    }
  }

  // background-color: #fff;
  &.bottom-curve {
    bottom: 0;
    top: auto;
  }
}

.subMenu {
  margin: 0 0 1rem;
}
