.layout-topbar {
  position: fixed;
  height: 8.2rem;
  z-index: 999;
  left: 2.4rem;
  top: 0;
  padding: 0;
  background-color: var(--surface-h);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  left: 27.4rem;
  right: 2.2rem;

  .sidebar-hide & {
    @include bp(smd-min-2) {
      left: 10.5rem;
    }
  }

  @include bp(llg-max) {
    height: 8rem;
    left: 27.3rem;
    right: 2.4rem;

    .sidebar-hide & {
      @include bp(smd-min-2) {
        left: 10.2rem;
      }
    }
  }

  @include bp(smd-max) {
    left: 1.5rem;
    right: 1.5rem;
    padding: 0 1.5rem;
    justify-content: space-between;
    height: 6.4rem;
  }

  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--heading-text);
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: 500;
    width: 40rem;
    order: 2;
    text-transform: capitalize;

    .logo-text {
      @include bp(smd-max) {
        display: none;
      }
    }

    img {
      height: 4rem;
    }

    span {
      text-align: center;
    }

    &:focus {
      @include focused();
    }
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    @include bp(xs-max) {
      width: 3.5rem;
      height: 3.5rem;
      min-width: 3.5rem;
    }

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 2rem;

      @include bp(xs-max) {
        font-size: 1.6rem;
      }
    }

    span {
      font-size: 1rem;
      display: none;
    }

    .active-menu-ic {
      display: none;
    }
  }

  .layout-menu-button {
    order: 1;
    display: none;

    @include bp(smd-max) {
      display: flex;
    }
  }

  .layout-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    order: 3;
    padding-right: 0.7rem;

    .layout-topbar-button {
      margin-left: 1rem;
    }
  }

  .user-dropdown {
    cursor: pointer;

    .user-img {
      height: 3.5rem;
      width: 3.5rem;
      font-size: 1.4rem;
      border-radius: 1.8rem;
      background-color: var(--primary-color) !important;

      @include bp(xs-max) {
        height: 3rem;
        width: 3rem;
        font-size: 1.3rem;
      }
    }
  }

  .MuiAvatar-root {
    background-color: var(--primary-color);
  }

  .topbar-mobile-logo {
    display: none;
    order: 2;
    margin-left: 1rem;

    @include bp(smd-max) {
      display: flex;
    }
  }
}

.user-name-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  color: var(--heading-text);
  border-radius: $borderRadius;

  img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2.5rem;
    margin-right: 1.2rem;
  }

  &:hover {
    background: var(--link-hover);
  }

  .user-img {
    margin-right: 1rem;
    font-size: 1.6rem;
  }
}

.notification-popup {
  padding: 0.4rem 0;
  position: fixed !important;
  left: auto !important;
  right: 10rem !important;
  top: 7.5rem !important;
  display: block !important;

  @include bp(llg-max) {
    right: 7.5rem !important;
    top: 6.5rem !important;
  }

  @include bp(smd-max) {
    right: 7rem !important;
    top: 5.5rem !important;
  }
}

.notification-menu {
  width: 4.2rem;
  height: 4.2rem;

  .menu-link {
    width: 100%;
    height: 100%;
    border-radius: 2.5rem;
    align-items: center;
    justify-content: center;
    position: relative;

    button {
      height: 4.2rem;
      min-width: 4.2rem;
      max-height: 4.2rem;
      line-height: 4.2rem;
    }

    i {
      font-size: 2rem;
    }

    .MuiAvatar-root {
      background-color: var(--primary-color);
    }

    .notification-number {
      position: absolute;
      top: 0.2rem;
      right: 0.6rem;
      font-size: 1.2rem;
      line-height: 1.23;
      font-weight: 400;
      background-color: #e57373;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}

.p-menu {
  &.user-menu-popup {
    position: fixed !important;
    left: auto !important;
    right: 5.7rem !important;
    top: 7.5rem !important;
    width: 23rem;
    padding: 0.4rem 0 0.4rem;
    display: block !important;

    @include bp(llg-max) {
      right: 3.7rem !important;
      top: 6.5rem !important;
    }

    @include bp(smd-max) {
      right: 2.5rem !important;
      top: 5.5rem !important;
    }

    .user-name-wrapper {
      background-color: transparent !important;
    }

    .p-menuitem {
      padding: 0;
      &:not(.p-highlight):not(.p-disabled).p-focus {
        > .p-menuitem-content {
          background: transparent;
        }
      }
    }
    .user-name-wrapper {
      padding: 1rem 1.4rem;
    }
  }

  .p-menuitem {
    padding: 0.4rem 0.8rem;

    .p-menuitem-link {
      padding: 1rem 1.4rem;
      display: flex;
      border-radius: $borderRadius;
      &:not(.p-disabled) {
        &:hover {
          background: var(--link-hover);
        }
      }
      .p-menuitem-text {
        font-size: 1.4rem;
      }
      .p-menuitem-icon {
        display: block;
        line-height: 1;
      }
    }
  }
}

.topbar-menu-list {
  margin: 0 -0.8rem;

  @include bp(xs-max) {
    margin: 0 -0.4rem;
  }

  .topbar-menu-item {
    padding: 0 0.2rem;

    &.notification-wrapper {
      padding-left: 0;
    }

    &.profile-wrapper {
      background-color: var(--surface-a);
      padding-right: 0;
      margin-left: 1.6rem;
      border-radius: 1.8rem;
      border: 0.1rem solid var(--surface-a);
      cursor: pointer;
      transition: 1s all;

      &:hover {
        transition: 1s all;
        box-shadow: rgba(149, 157, 165, 0.2) 0 0.8rem 2.4rem;
      }

      @include bp(smd-max) {
        border-color: var(--light-gray6);
      }

      @include bp(sm-max) {
        background-color: transparent;
        padding-right: 0.4rem;
        border: 0;
      }

      .user-role {
        padding: 0 1.2rem 0 1rem;
        font-size: 1.4rem;
        line-height: 1.2;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;

        @include bp(sm-max) {
          display: none;
        }
      }
    }
  }

  .theme-button {
    .darkmode-icon {
      display: none;
    }
  }
}

.layout-mobile-sidebar-active {
  .layout-topbar {
    .layout-topbar-button {
      .default-ic {
        display: none;
      }

      .active-menu-ic {
        display: block;
      }
    }
  }
}

@media (min-width: 991px) {
  .layout-topbar-block {
    &::after {
      width: calc(100% - 27rem);
      margin: 0 0 0 auto;
      border-top-left-radius: 3.5rem;
    }
  }
}

@media (min-width: 992px) {
  .layout-menu-button {
    display: none;
  }
}

@media (max-width: 991px) {
  .layout-topbar {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0 0.3rem 0.3rem 0 #00000008;

    .layout-topbar-logo {
      width: auto;
      order: 2;
      font-size: 1.35rem;
      padding: 0 0.5rem 0 0;
    }

    .layout-menu-button {
      margin-right: 0;
      order: 1;
    }

    .layout-topbar-menu-button {
      margin-left: 0;
      order: 3;
      height: 2.5rem;
      min-width: 2.5rem;
      width: 2.5rem;

      i {
        font-size: 1rem;
      }
    }

    .layout-topbar-menu {
      margin-left: 0;
      flex-direction: column;

      &.layout-topbar-menu-mobile-active {
        display: block;
      }

      .layout-topbar-button {
        margin-left: 0;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 1.2rem;
        padding: 1rem;

        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          font-weight: medium;
          display: block;
        }
      }
    }
  }
}

.dropdown_menu {
  .heading {
    display: flex;
    padding-bottom: 0.5rem;
    padding-right: 0.75rem;
    justify-content: space-between;
    align-items: center;

    .clear-all {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.notification-modal {
  max-width: 45rem;
  min-width: 45rem;
  overflow-y: auto;
  max-height: 20rem;
  min-height: 20rem;
  display: block;
  @include bp(sm-max) {
    min-width: 30rem;
  }
  .null-notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: inherit;
    color: var(--heading-text);
  }
}

.user-info {
  word-break: break-all !important;
  white-space: pre-wrap !important;

  &.new-notification {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      background: #ff4100;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      right: 1.4rem;
    }

    .user-data p {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }

  .user-avtar {
    max-width: 4.8rem;
    flex: 0 0 4.8rem;

    .MuiAvatar-root {
      width: 4.8rem;
      height: 4.8rem;
      margin: 0;
    }
  }

  .user-data {
    flex: 0 0 calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    padding: 0 0 0 1.6rem;
    word-break: break-word !important;
    font-family: 'Public Sans', sans-serif;
    color: var(--heading-text);

    span {
      color: #70778e;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      display: block;
    }

    p {
      font-weight: 400;
      font-size: 1.4rem;
      margin: 0;
    }
  }
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}
