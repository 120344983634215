.interview-process-wrapper {
    .p-accordion {
        margin-bottom: 3.6rem;

        @include bp(lg-max) {
            margin-bottom: 2.8rem;
        }

        @include bp(sm-max) {
            margin-bottom: 2.2rem;
        }

        .p-accordion-tab {
            margin-bottom: 1.8rem;
            background: var(--surface-a);
            border-radius: 2rem;
            overflow: hidden;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }

            .p-accordion-header {
                .p-accordion-header-link {
                    padding: 2rem 2rem 1.6rem 2rem;
                    outline: none;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    gap: 1.5rem;
                    border: 0;
                    border-bottom: 0.1rem solid transparent;
                    background-color: transparent;

                    @include bp(lg-max) {
                        padding: 1.5rem 1.5rem 1.2rem 1.5rem;
                    }

                    .p-accordion-toggle-icon {
                        width: 1.2rem;
                        height: 0.8rem;
                        margin-right: 0;
                        transition: all 0.5s;
                        background-image: url('../../images/interview-process-dropdown-arrow.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 1.2rem 0.8rem;
                        position: relative;
                        transition: all 0.5s;
                        top: -0.2rem;

                        path {
                            display: none;
                        }
                    }

                    .p-accordion-header-text {
                        .accordian-title-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: 1.6rem;

                            @include bp(lg-max) {
                                gap: 1rem;
                            }

                            .number-block {
                                flex: 0 0 4rem;
                                max-width: 4rem;
                                height: 4rem;
                                position: relative;
                                top: -0.2rem;

                                @include bp(lg-max) {
                                    flex: 0 0 3rem;
                                    max-width: 3rem;
                                    height: 3rem;
                                }

                                em {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                    height: 100%;
                                    font-style: normal;
                                    background: var(--circle-gradient);
                                    color: var(--surface-a);
                                    font-size: 1.8rem;
                                    font-weight: 500;

                                    @include bp(lg-max) {
                                        font-size: 1.6rem;
                                    }
                                }
                            }

                            .accordian-title {
                                flex: 1;
                                width: 100%;

                                p {
                                    font-size: 1.8rem;
                                    font-weight: 500;
                                    line-height: 1.21;
                                    color: var(--gray-900);
                                    margin-bottom: 1rem;

                                    @include bp(lg-max) {
                                        margin-bottom: 0.5rem;
                                    }

                                    @include bp(sm-max) {
                                        font-size: 1.6rem;
                                        margin-bottom: 0.4rem;
                                    }

                                }

                                span {
                                    font-size: 1.4rem;
                                    font-weight: 400;
                                    line-height: 1.21;
                                    color: var(--black);
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .p-accordion-header-link {
                        border-color: transparent;
                        background-color: transparent;
                    }
                }
            }

            .p-accordion-content {
                padding: 1.6rem 2rem 2rem 2rem;
                border: none;
                background: var(--surface-a);
                border-radius: 0;

                @include bp(lg-max) {
                    padding: 1.2rem 1.5rem 1.5rem 1.5rem;
                } 
                a{
                    text-decoration: underline;
                    color: var(--primary-color);
                    transition: all 0.5s;
                    &:hover{
                        text-decoration: none;
                    }
                }
                strong{
                    font-weight: 700;
                }
                b{
                    font-weight: 700;
                }
                p {
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: var(--gray-900);
                    line-height: 1.5;

                    &+p {
                        margin-top: 1rem;
                    }

                    &+ul {
                        margin-top: 1rem;
                    }
                    &+ol {
                        margin-top: 1rem;
                    }
                }

                ul {
                    &+ul {
                        margin-top: 1rem;
                    }

                    &+ol {
                        margin-top: 1rem;
                    }

                    &+p {
                        margin-top: 1rem;
                    }

                    li {
                        color: var(--gray-900);
                        font-weight: 400;
                        font-size: 1.4rem;
                        line-height: 1.7;
                        padding-left: 1.2rem;
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "";
                            top: 0.9rem;
                            left: 0;
                            width: 0.4rem;
                            height: 0.4rem;
                            background-color: var(--gray-900);
                            border-radius: 100%;

                        }
                    }
                }

                ol {
                    list-style-type: decimal;
                    margin-left: 1.7rem;
                    &+ul {
                        margin-top: 1rem;
                    }

                    &+ol {
                        margin-top: 1rem;
                    }
                    &+p {
                        margin-top: 1rem;
                    }

                    li {
                        color: var(--gray-900);
                        font-weight: 400;
                        font-size: 1.4rem;
                        line-height: 1.7;
                    }
                }
            }

            &.p-accordion-tab-active {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-color: var(--light-gray);

                        .p-accordion-toggle-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .important-notes-block {
        .title {
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--gray-900);
            line-height: 1.18;
            margin-bottom: 1rem;
        }

        ul {
            li {
                color: var(--black);
                font-weight: 400;
                font-size: 1.3rem;
                line-height: 1.7;
                padding-left: 1.2rem;
                position: relative;

                &+li {
                    margin-top: 0.4rem;
                }

                &::before {
                    position: absolute;
                    content: "";
                    top: 0.9rem;
                    left: 0;
                    width: 0.4rem;
                    height: 0.4rem;
                    background-color: var(--black);
                    border-radius: 100%;

                }
            }
        }
    }
}