.custom-tabbing {
  &.p-tabview {
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
    .p-tabview-panels {
      padding: 0;
    }
    .p-tabview-nav-content {
      border-bottom: 0.1rem solid #ececec;
      overflow: hidden;
      height: 5rem;
      @include bp(smd-max) {
        height: 4.5rem;
      }
      .p-tabview-nav {
        border: 0;
        padding-bottom: 1rem;
        flex-wrap: nowrap;
        overflow-x: auto;
        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 0.5rem;
          position: relative;
          padding-top: 0.8rem;
        }
        &::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0.5rem;
          background-color: var(--gray-400);
          opacity: 0.8;
          max-height: 10rem;
        }
        li {
          .p-tabview-nav-link {
            height: 5rem;
            padding: 1.5rem;
            display: flex;
            margin: 0;
            box-shadow: none;
            outline: none;
            position: relative;
            opacity: 1;
            &::after {
              position: absolute;
              content: '';
              left: 1.5rem;
              right: 0;
              bottom: 0;
              width: calc(100% - 3rem);
              border-bottom: 0.2rem solid transparent;
              z-index: 9;
            }
            @include bp(smd-max) {
              height: 4.4rem;
              padding: 1.5rem 1rem;
            }
          }
          &.p-highlight {
            .p-tabview-nav-link {
              color: var(--secondary-color);
              &::after {
                border-color: var(--secondary-color);
              }
            }
          }
          &.p-tabview-ink-bar {
            display: none;
            bottom: 1.5rem;
          }
        }
      }
    }
    .white-box {
      overflow: inherit !important;
      box-shadow: none;
      .p-datatable-header {
        background-color: #fff;
      }
      .p-datatable-emptymessage {
        td {
          text-align: center;
          line-height: 3.3rem;
        }
      }
      .p-datatable-table {
        table-layout: auto;
        tr {
          td {
            &.table-action-col {
              text-align: right;
              .table-actions-wrapper {
                display: flex;
                align-items: center;
                button {
                  &.p-button {
                    &.p-button-icon-only {
                      &.p-button-rounded {
                        height: 3.2rem;
                        width: 3.2rem;
                        padding: 0;
                        border: none;
                        color: #333333 !important;
                        background-color: transparent;
                        &:hover {
                          background-color: var(--primary-color-overlay);
                        }
                      }
                    }
                  }
                  + button {
                    margin-left: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
      .react-tooltip {
        padding: 0.8rem;
        border-radius: 0.5rem;
        font-size: 1.3rem;
        line-height: 1.15;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }
}
.technology-chip-list {
  .technology-chip {
    background-color: var(--primary-color) ;
    border: none;
    border-radius: 0.3rem;
    color: #fff !important;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.5rem 0.8rem;
    .p-chip-text {
      line-height: inherit;
      margin: 0;
    }
  }
}
