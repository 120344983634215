/* General */
$fontSize: 1.6rem;
$borderRadius: 0.6rem;
$transitionDuration: 0.2s;

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --surface-g: #f5f5f5;
  --surface-h: #f2f2f2;
  --text-color: #787878;
  --text-color-secondary: #6c757d;
  --primary-color: #FA8503;
  --primary-color-text: #ffffff;
  --font-family: 'Public Sans', sans-serif;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #0d0d0d;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 0.5rem;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 0.2rem #c7d2fe;
  --maskbg: rgba(0, 0, 0, 0.4);
  --gradient1: linear-gradient(90deg, #FA8503 0%, #FFBF77 100%);
  --link-hover: #f8f8f8;
  --login-bg: #f8f7fa;
  --login-text: #33303c;
  --login-text2: #8e8d8d;
  --focus-label-color: #7367f0;
  --astrict-color: #ff4444;
  --rgba1: rgba(51, 48, 60, 0.04);
  --rgba2: rgba(228, 230, 244, 0.68);
  --rgba3: rgba(228, 230, 244, 0.04);
  --rgba4: rgba(75, 70, 92, 0.02);
  --white-rgb1: rgb(248, 247, 250);
  --light-green: #d4faff;
  --light-green-rgba: rgba(212, 250, 255, 0.2);
  --dark-green-rgba: rgba(129, 199, 132, 0.2);
  --active-user-bg: rgb(40 199 111 / 10%);
  --inactive-user-bg: #e57373;
  --active-text: #1fb007;
  --inactive-text: #f42020;
  --black: #000000;
  --white-rgba: rgba(255, 255, 255, 0.2);
  --gray-rgba: rgba(75, 75, 75, 0.2);
  --light-gray: #ececec;
  --white1: #f4f4f4;
  --white2: #f4f2f2;
  --white3: #f0f0f0;
  --light-gray1: #9b9a9a;
  --light-gray2: #bbbbbb;
  --light-gray3: #8e8d8d;
  --light-gray4: #e8e8e8;
  --light-gray5: #dddddd;
  --light-gray6: #b9b8bb;
  --border-gray: #cac9cc;
  --black1: #4b4b4b;
  --black2: #495057;
  --heading-text: #333333;
  --primary-color-overlay: rgba(115, 103, 240, 0.1);
  --primary-color-overlay2: rgba(115, 103, 240, 0.2);
  --primary-color-overlay3: rgba(115, 103, 240, 0.3);
  --secondary-btn-bg: rgba(228, 228, 232, 0.2);
  --green-rgba: rgba(31, 176, 7, 0.1);
  --red-rgba: rgb(244, 32, 32, 0.1);
  --border-gray2:#d6d6d6;
  --text-color2:#343a40;
  --placeholder-gray:#9A9A9A;
  --circle-gradient:linear-gradient(270deg, #FFBF77 0%, #FFA541 50%, #FA8503 100%);
  /* dark-theme-color-variables */
  --surface-body: #0e0c23;
  --surface-body-rgba: rgba(37, 41, 60, 0.9);
  --dark-boxes: #18162b;
  --title-dark-text: rgba(228, 230, 244, 1);
  --title-light-text: rgba(228, 230, 244, 0.38);
  --title-light-text2: rgba(228, 230, 244, 0.68);
  --border-light-color: #2c2a40;
  --input-dark-text: #767992;
  --link-hover-bg: rgba(207, 211, 236, 0.07);
  --secondary-color: #0b2e58;
  --btn-gradient: linear-gradient(90deg, #FA8503 0%, #FFA541 72.14%, #FFBF77 100%);
  --btn-border-color: #FB8809;
  --content-block-dot-color: #156ba3;
}