// FAQ section lading page
.tatva-faq-section {
  padding: 7rem 0 3.5rem;
  @include bp(lg-max){
    padding: 5rem 0 3rem;
  }
  @include bp(sm-max){
    padding: 4rem 0 3rem;
  }
  .container {
    max-width: 119rem;
    > p {
      font-size: 1.8rem;
      line-height: 1.8;
      text-align: center;
      margin-bottom: 3.9rem;
      @include bp(xxlg-max){
        margin-bottom: 3.2rem;
      }
      @include bp(lg-max){
        line-height: 1.5;
        margin-bottom: 2rem;
      }
      @include bp(sm-max){
        margin-bottom: 2.5rem;
      }
    }
  }
  h2 {
    margin-bottom: 1.5rem;
    text-align: center;

    @include bp(sm-max){
      margin-bottom: 1rem;
    }
  }
  .p-accordion {
    .p-accordion-tab {
      margin: 0;
      border: none;
      border-top: 0.1rem solid #eee;
      &:first-child{
        border-top: none;
        .p-accordion-header{
          .p-accordion-header-link {
            padding-top: 0;
          }
        }
      }
      .p-accordion-header {
        border-bottom: 0.1rem solid transparent;
        &.p-highlight {
          border-color: #eee;
          .p-accordion-header-link {
            .p-accordion-header-text {
              &::after {
                transform:translateY(-50%) rotate(180deg);
              }
            }
          }
        }
        .p-accordion-header-link {
          padding:2.5rem;
          display: inline-block;
          width: 100%;
          background-color: #fff;
          border: none;
          outline: none;
          box-shadow: none;
          @include bp(smd-max){
            padding: 2.5rem;
          }
          @include bp(sm-max){
            padding: 2rem 1.5rem;
          }
          .p-accordion-header-text {
            color: #0b2e58;
            font-size: 2.1rem;
            font-weight: 400;
            line-height: 1.35;
            position: relative;
            padding-right: 5rem;
            display: inline-block;
            width: 100%;
            @include bp(smd-max){
              font-size: 2rem;
            }
            @include bp(sm-max){
              font-size: 1.8rem;
              padding-right: 4rem;
            }
            &::after {
              position: absolute;
              content: '';
              background-image: url(/assets/images/faq-right-arrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              opacity: 1;
              top: 50%;
              transform: translateY(-50%);
              height: 1rem;
              width: 1.9rem;
              right: 0;
              background-size: 1.9rem;
              transition: 0.5s;

              @include bp(smd-max){
                width: 1.8rem;
                height: 0.9rem;
                background-size: 1.8rem;
              }
              @include bp(sm-max){
                width: 1.6rem;
                height: 0.8rem;
                background-size: 1.6rem;
              }
            }
          }
          svg {
            display: none;
          }
        }
        &:hover{
          .p-accordion-header-link{
            background-color: #FFF;
            border-color: #EEE;
          }
        }
      }
      .p-accordion-content {
        padding: 2rem 2.5rem;
        position: relative;
        background-color: #f8f8f8;
        border: none;
        border-radius: 0;
        @include bp(sm-max){
          padding: 1.2rem 1.5rem;
        }
        p {
          font-size: 1.8rem;
          line-height: 1.7;

          @include bp(lg-max){
            font-size: 1.6rem;
          }
          @include bp(sm-max){
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

// FAQ page
.faq-page-main{
  @include bp(smd-max) {
    padding: 1.5rem 0 0.5rem;
  }
  .faq-wrapper {
    background: #FFFFFF;
    border-radius: 1rem;
    padding: 2.5rem 2.5rem 1.3rem;
    height: 100%;
    @include bp(lg-max) {
      padding: 2rem 2rem 0.8rem;
    }
    @include bp(smd-max) {
      padding: 1.5rem 1.5rem 0.3rem;
    }
    .p-accordion{
      .p-accordion-tab{
        margin-bottom: 0;
        border-top: 0.1rem solid var(--light-gray);
        &:first-child{
          border-top: none;
          .p-accordion-header{
            .p-accordion-header-link{
              padding-top: 0;            
            }
          }
        }
        .p-accordion-header{
          .p-accordion-header-link{
            padding:1.2rem 1rem;
            color: var(--text-color2);
            outline: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            gap: 1.5rem;
            border: 0;
            border-bottom: 0.1rem solid transparent;
            background-color: transparent;
            .p-accordion-toggle-icon{
              width: 1.2rem;
              height: 0.8rem;
              margin-right: 0;              
              transition: all 0.5s;
              background-image: url('../../images/faq-dropdown-arrow.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 1.2rem 0.8rem;
              position: relative;
              // @include bp(sm-max) {
              //   width: 1.3rem;
              //   height: 1.3rem;
              // }              
              path{
                display: none;
              }
            }
            .p-accordion-header-text{
              span{
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.5;
              }
            }
          }
          &:hover{
            .p-accordion-header-link{
              border-color: transparent;
              background-color: transparent;
            }
          }
        }
        .p-accordion-content{
          padding: 1.2rem 1rem;
          border: none;
          background: var(--light-gray);
          border-radius: 0;
          p{
            font-size: 1.6rem;
            font-weight: 400;
            color: var(--text-color2);
            line-height: 1.5;
          }
        }
        &.p-accordion-tab-active{
          .p-accordion-header{
            .p-accordion-header-link{
              border-color: var(--light-gray);
              .p-accordion-toggle-icon{
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}