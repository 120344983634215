.loading {
  width: 100vw;
  min-height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: absolute;
  z-index: 1500;
  top: 0;
  div {
    margin-top: 10rem;
    width: 5rem;
    height: 5rem;
    border: 0.5rem solid #ffff;
    border-top: 0.5rem solid var(--primary-color);
    border-right: 0.5rem solid var(--primary-color);
    border-bottom: 0.5rem solid var(--primary-color);
    border-radius: 50%;
    animation: loadingRotate 1500ms linear infinite;
    -webkit-animation: loadingRotate 1500ms linear infinite;
  }
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(360deg);
  }
}

.p-progress-spinner-circle {
  stroke: #9d94f4 !important;
}
