.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 8.2rem 2.4rem 5rem 2.4rem;
  transition: margin-left $transitionDuration;
  margin-left: 26rem;
  .sidebar-hide & {
    @include bp(smd-min-2) {
      margin-left: 8rem !important;
    }
  }
  @include bp(llg-max) {
    padding: 8rem 2.4rem 2.4rem;
  }
  &:after {
    content: '';
    height: 5rem;
    background-color: var(--surface-h);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    left: 26rem;
    display: none;
    .sidebar-hide & {
      @include bp(smd-min-2) {
        left: 9.1rem;
      }
    }
    @include bp(llg-max) {
      height: 2.4rem;
    }
    @include bp(smd-max) {
      width: 100%;
      display: none;
    }
  }
  @include bp(smd-max) {
    padding: 6.4rem 1.5rem 1rem;
  }
  .mobile-portal-title {
    color: var(--surface-900);
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    padding: 2rem 0;
    display: none;
    text-transform: capitalize;
    @include bp(smd-max) {
      display: block;
      padding: 0;
    }
  }
}

.layout-main {
  flex: 1 1 auto;
}

.light-theme-logo {
  display: block;
}

.p-tooltip {
  .p-tooltip-text {
    padding: 0.6rem 0.8rem !important;
    font-size: 1.4rem;
    white-space: nowrap;
    word-break: normal;
    // white-space: normal;
  }
}

.__react_component_tooltip {
  white-space: nowrap !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  background-color: var(--surface-body) !important;
  border-radius: 0.5rem !important;
  font-weight: 400;
  &::after {
    background-color: var(--surface-body) !important;
  }
}
.rbc-today {
  background-color: #ebe9ff !important;
}
.rbc-day-slot.rbc-today .rbc-time-slot {
  border-top: 0.1rem solid #d5d4e4;
}

.p-badge {
  font-size: 1.2rem !important;
  font-weight: 400;
  padding: 0.5rem 0.8rem;
  min-width: auto;
  border-radius: 0.3rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #fff;
  text-transform: capitalize;
  &.p-badge-info {
    background-color: #d4e4fa;
    color: #0d4ea6;
  }
  &.p-badge-success {
    background-color: #c7ebd1;
    color: #075e45;
  }
  &.p-badge-danger {
    background-color: #fadcd9;
    color: #a1160b;
  }
  &.p-badge-warning {
    background-color: #faf6cf;
    color: #1c2b36;
  }
  // .p-badge.p-badge-secondary {
  //     background-color: #64748b !important;
  //     color: #ffffff !important;
  // }
}

.primary-color {
  color: var(--primary-color);
}

// body, body *{
//     transition: all 0.2s !important;
// }
.fade-appear .layout-main-container,
.fade-enter .layout-main-container {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active .layout-main-container,
.fade-enter.fade-enter-active .layout-main-container {
  opacity: 1;
  transition: opacity 400ms linear 200ms;
}

.fade-exit .layout-main-container {
  opacity: 1;
}

.fade-exit.fade-exit-active .layout-main-container {
  opacity: 0;
  transition: opacity 200ms linear;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex {
  display: flex;
}

.p-accordion-header-link {
  width: 100% !important;
}
