@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800;900&display=swap');
body {
  font-size: 1.6rem;
  line-height: 1.38;
}

/* Reset */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

:focus {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input {
  opacity: 1;
}

input::-webkit-input-placeholder {
  opacity: 1 !important;
}

input::-moz-placeholder {
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  opacity: 1 !important;
}

input::-ms-input-placeholder {
  opacity: 1 !important;
}

input::placeholder {
  opacity: 1 !important;
}

input::-webkit-input-placeholder {
  opacity: 1 !important;
}

input::-moz-placeholder {
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  opacity: 1 !important;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
}

a:active,
a:hover,
a:focus {
  outline: 0;
}

figure,
form {
  margin: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'],
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

select {
  color: #000;
  outline: none;
}

a {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

.pi-eye, .pi-eye-slash {
  cursor: pointer;
}

/* .container {
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/login-background.jpg');
  background-position: center bottom;
  background-size: cover;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;

  padding: 12px 16px 0 0;

  button {
    margin-left: 10px;
  }
} */
