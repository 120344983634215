.user-profile-wrapper {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .radio-outer-wrapper {
      display: flex;
    }

    .left-col {
      margin-bottom: 2rem;
      .form-col {
        .dark-theme & {
          background-color: var(--dark-boxes);
        }

        +.form-col {
          margin-top: 2rem;
        }

        &.form-col-3 {
          max-width: 33.33%;
          flex: 0 0 33.33%;
        }
      }

      &.resume-col {
        .profile-box {
          padding: 2rem;

          @include bp(sm-max) {
            padding: 1.5rem;
          }

          .box-inner {
            width: 100%;

            .title-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 2rem;
              padding-bottom: 2rem;
              position: relative;

              @include bp(sm-max) {
                margin-bottom: 1.5rem;
                padding-bottom: 1.5rem;
              }

              &::after {
                position: absolute;
                content: '';
                width: calc(100% + 4rem);
                height: 0.1rem;
                left: -2rem;
                bottom: 0;
                background-color: var(--light-gray4);
              }

              h3 {
                font-size: 2.4rem;

                @include bp(lgl-max) {
                  font-size: 2.2rem;
                }

                @include bp(xs-max) {
                  font-size: 2rem;
                }
              }
            }

            .user-details {
              margin-bottom: 2rem;
              display: flex;

              .user-name {
                font-size: 1.8rem;
                color: var(--text-color);

                @include bp(sm-max) {
                  font-size: 1.6rem;
                }
              }
            }

            .upload-resume {
              @include bp(lg-max) {
                text-align: center;
              }

              .border-btn {
                width: 100%;
                color: var(--primary-color) !important;
                border-color: var(--primary-color) !important;
                display: inline-block;

                @include bp(lg-max) {
                  width: auto;
                }

                .p-button-label {
                  font-weight: 400 !important;
                }
              }

              .p-button-icon-left {
                margin-right: 0.8rem;
                font-size: 1.4rem;
              }
            }

            .edit-delete-btn {
              button {
                width: auto;

                &.edit-btn {
                  @include bp(xs-max) {
                    margin-right: 1rem;
                    padding-right: 1rem;
                  }
                }

                span {
                  @include bp(xs-max) {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
        }
      }

      .p-error {
        display: block;
        text-align: center;
        padding: 1rem 1rem 0;

        &:empty {
          display: none;
        }
      }

      .profile-box {
        height: 100%;

        @include bp(sm-max) {
          padding: 0;
        }
        .box-inner {
          display: flex;
          align-items: center;
          gap: 2rem;
          @include bp(smd-max){
            gap: 1.5rem;
          }

          .profile-img {
            .user-img {
              margin: 0;
              background-color: var(--primary-color) !important;
            }
          }

          .edit-delete-btn {
            margin-bottom: 1.2rem;
            .border-none{
              flex-direction: row-reverse;
              background-color: transparent !important;
              em{
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            @include bp(smd-max){
              margin-bottom: 0.8rem;
            }
            .p-disabled{
              cursor: not-allowed;
            }
          }

          .reset-password-div {
            padding: 0;
            min-height: auto;
          }

          .p-avatar-circle {
            background-color: var(--secondary-color) !important;
            font-size: 3rem;
            line-height: 1.16;
            font-weight: 400;
            @include bp(smd-max){
              font-size: 2.6rem;
            }
          }
        }

        .dark-theme & {
          border-color: var(--border-light-color);
        }
      }

      .profile-img {
        text-align: center;

        .p-avatar,
        img {
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          margin: 0;
          object-fit: cover;

          @include bp(smd-max){
            width: 8rem;
            height: 8rem;
          }
        }
      }

      .user-details {
        margin-bottom: 1.5rem;
        @include bp(smd-max){
          margin-bottom: 1rem;
        }

        .user-name {
          font-weight: 400;
          font-size: 2rem;
          line-height: 1.6rem;
          color: var(--heading-text);

          @include bp(sm-max) {
            font-size: 1.8rem;
          }

          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
      }

      .edit-delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .pi {
          font-size: 1.6rem;
        }

        button {
          border: none;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 1.9rem;
          color: var(--text-color);
          background: none;
          padding: 0;
          box-shadow: none;
          outline: 0;
          height: auto;
          box-shadow: none !important;

          &.delete-btn {
            color: var(--text-color);
          }

          .p-button-icon-left {
            margin-right: 0.8rem;
            @include bp(xs-max){
              margin-right: 0.5rem;
            }
          }

          @include bp(sm-max) {
            font-size: 1.5rem;
          }

          span {
            font-weight: 400;
            font-size: 1.4rem;

            .dark-theme & {
              color: var(--title-light-text2);
            }
          }

          &.edit-btn {
            margin-right: 1.5rem;
            padding-right: 1.5rem;
            padding-left: 0.3rem;
            position: relative;
            border-radius: 0;           
            @include bp(xs-max){
              margin-right: 1rem;
              padding-right: 1rem;
            }

            &:after {
              content: '';
              width: 0.1rem;
              height: 100%;
              right: 0;
              position: absolute;
              background-color: var(--border-gray2);
            }
          }
        }
      }

      .notification-div {
        @include bp(lgl-max) {
          padding: 1.5rem;
        }

        .dark-theme & {
          border-color: var(--border-light-color);
        }

        label {
          font-weight: 400 !important;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: var(--text-color);

          @include bp(sm-max) {
            font-size: 1.5rem;
          }

          .dark-theme & {
            color: var(--title-light-text2);
          }
        }

        .p-inputswitch {
          width: 4.8rem;
          height: 2.8rem;

          @include bp(sm-max) {
            width: 4rem;
            height: 2.4rem;
          }

          .p-inputswitch-slider {
            width: 4.8rem;
            height: 2.8rem;
            background-color: var(--light-gray5);
            border-radius: 2rem;

            @include bp(sm-max) {
              width: 4rem;
              height: 2.4rem;
              border-radius: 3rem;
            }

            &:before {
              width: 1.8rem;
              height: 1.8rem;
              margin-top: -0.9rem;

              @include bp(sm-max) {
                width: 1.6rem;
                height: 1.6rem;
                margin-top: -0.8rem;
              }
            }
          }

          &.p-inputswitch-checked {
            .p-inputswitch-slider {
              background-color: var(--primary-color);

              &:before {
                transform: translateX(2.2rem);
                -webkit-transform: translateX(2.2rem);
                -moz-transform: translateX(2.2rem);
                -ms-transform: translateX(2.2rem);
                -o-transform: translateX(2.2rem);

                @include bp(sm-max) {
                  transform: translateX(1.7rem);
                  -webkit-transform: translateX(1.7rem);
                  -moz-transform: translateX(1.7rem);
                  -ms-transform: translateX(1.7rem);
                  -o-transform: translateX(1.7rem);
                }
              }
            }
          }
        }

        &.reset-password-div {
          justify-content: center;

          @include bp(lgl-max) {
            min-height: 5.9rem;
          }

          .primary-link {
            padding: 0;
            font-weight: 400 !important;
            font-size: 1.5rem;
            line-height: 1.1;
            color: var(--secondary-color) !important;
          }
        }
      }

      .form-row-wrapper {
        align-items: flex-end;
      }
    }

    .right-col {
      .gender {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.2rem;
        margin-left: 1.6rem;
        padding: 0.6rem;

        input[type='radio'] {
          border-radius: 50% !important;
        }

        .male>.label {
          margin-left: 0.8rem;
        }

        .female>.label {
          margin-left: 0.8rem;
        }
      }
    }

    .profile-info-wrapper {
      .form-row-wrapper {
        align-items: flex-end;
        margin: 0 -1.2rem -2rem;
        @include bp(lg-max){
          margin: 0 -0.8rem -1rem;
        }
        @include bp(xxs-max){
          margin: 0 0 -1.5rem;
        }
        .form-col {
          @include bp(lg-max){
            padding: 0 0.8rem;
            margin-bottom: 1rem;
            &.form-col-3 {
              @include bp(sm-max){
                max-width: 50%;
                flex: 0 0 50%;
              }
              @include bp(xxs-max){
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0;
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }
    .date-picker-wrapper {
      .react-datepicker__tab-loop {
        .react-datepicker-popper {
          transform: none !important;
          width: 100% !important;
        }
      }
    }
    .form-btn-wrapper {
      button {
        @include bp(xxs-max) {
          width: auto !important;
        }

        &:not(:last-child) {
          @include bp(xxs-max) {
            margin-right: 1.5rem !important;
          }
        }
      }
    }

    .radio-list-wrapper {
      .p-radiobutton {
        input {
          height: 100%;
          padding: 0;
        }
      }
    }
  }

  @include bp(smd-max){
    padding: 1.5rem 0 0.5rem;
  }

  .custom-form-group {
    .p-multiselect {
      position: relative;

      &.p-multiselect-chip {
        .p-multiselect-label {
          padding: 0 1rem 0 0;
        }

        .p-multiselect-token {
          margin-right: 0.8rem;

          .p-multiselect-token-icon {
            height: auto;
            width: 1.6rem;
          }
        }
      }

      .p-multiselect-label-container {
        margin-top: 0;
      }

      .p-multiselect-trigger {
        right: 0.1rem;
        top: 50%;
        transform: translateY(-50%);

        svg {
          height: auto;
          width: 1.2rem;
        }
      }
    }
  }
  .user-profile-inner-wrapper {
    padding: 1.5rem 1.5rem;
  }
}

.candidate-location-other {
  margin-top: 1rem;
}

.marital-status {
  margin-top: 1.8rem;
}

.white-box-block {
  max-width: 100%;
  flex-basis: 100%;
  margin-top: 2.6rem;
}

.white-box {
  background-color: var(--surface-a);
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0.1rem rgba(58, 54, 68, 0.03);
  height: 100%;
  padding: 2rem;

  .profile-title-wrapper {
    position: relative;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;

    &::after {
      position: absolute;
      content: '';
      width: calc(100% + 4rem);
      height: 0.1rem;
      left: -2rem;
      bottom: -0.1rem;
      background-color: var(--light-gray4);
    }
  }

  .dark-theme & {
    background-color: var(--dark-boxes);
  }

  @include bp(lgl-max) {
    padding: 1.5rem;
  }
}

.p-inputtext,
.p-inputnumber {
  width: 100%;
}

.inner-box-padding {
  padding-top: 2.5rem;
}

.p-multiselect-panel {
  &.technology-overlay {
    .p-multiselect-header {
      padding: 1rem;

      .p-checkbox {
        margin-right: 0;
      }

      .p-multiselect-filter-container {
        .p-multiselect-filter-icon {
          width: 1.6rem;
          height: auto;
          color: var(--light-gray2);
          top: 1.7rem;
        }
      }

      .p-multiselect-close {
        width: 3.2rem;
        height: 3.2rem;

        .p-multiselect-close-icon {
          width: 1.6rem;
          height: auto;
          color: var(--light-gray2);
        }
      }
    }

    .p-multiselect-items-wrapper {
      .p-multiselect-item {
        padding: 1rem;
        max-width: 100%;

        &.p-highlight {
          color: var(--primary-color);

          .p-checkbox {
            .p-checkbox-box {
              border-color: var(--primary-color);
              background: var(--primary-color);
            }
          }
        }
      }
    }

    .p-checkbox {
      width: 2rem;
      height: 2rem;

      .p-checkbox-box {
        width: 2rem;
        height: 2rem;

        .p-checkbox-icon {
          width: 1.2rem;
          height: auto;
        }
      }

      input {
        outline: none;
        border: none;
      }
    }
  }
}

.react-datepicker-wrapper {
  input {
    width: 100%;
  }
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  .dark-theme & {
    color: var(--input-dark-text) !important;
  }
}
.date-picker-wrapper{
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.7rem;
    line-height: 2.7rem;
    color: var(--black2);
    .dark-theme & {
      color: var(--title-dark-text);      
    }
  }
  .react-datepicker__day--disabled, 
  .react-datepicker__month-text--disabled, 
  .react-datepicker__quarter-text--disabled, 
  .react-datepicker__year-text--disabled{
    opacity: 0.8;
  }
}

.react-datepicker {
  border: 0 !important;
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem !important;

  .dark-theme & {
    background: var(--dark-boxes);
  }

  .react-datepicker__header {
    background: #fff;
    border: 0;
    border-radius: 0;
    text-align: left;
    padding: 1.4rem 1.4rem 0.4rem;

    .dark-theme & {
      background: var(--dark-boxes);
    }

    .react-datepicker-time__header {
      font-size: 1.4rem;
      color: var(--heading-text);
      font-weight: 700;
      text-align: left;

      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: auto !important;
  padding: 0.5rem 1.4rem !important;
  font-size: 1.4rem;
  color: var(--heading-text);
  text-align: left;

  .dark-theme & {
    color: var(--input-dark-text);
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background-color: transparent;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: var(--gray-400);
  opacity: 0.8;
  max-height: 10rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: #7367f026 !important;

  .dark-theme & {
    background: var(--link-hover-bg) !important;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--primary-color) !important;
  font-weight: 500 !important;

  .dark-theme & {
    background: var(--link-hover-bg) !important;
    color: #e4e6f4;
  }
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  .dark-theme & {
    background-color: var(--link-hover-bg) !important;
  }
}

.react-datepicker__year--container,
.react-datepicker__month-container {
  .react-datepicker__current-month {
    font-size: 1.4rem;
    text-align: center;
    color: var(--heading-text);
    padding-bottom: 0.5rem;

    .dark-theme & {
      color: var(--title-dark-text);
    }
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: var(--primary-color);
    font-weight: 500;
    color: var(--primary-color-text);
    &:hover {
      background-color: var(--primary-color);
    }
  }
}

// .react-datepicker__year-text:hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 50%;
}

.react-datepicker__tab-loop {
  .react-datepicker__navigation{
    top: 0.9rem;
    background-image: url('../../images/dropdown-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.4rem;
    &.react-datepicker__navigation--next{
      transform: rotate(-90deg);
    }
    &.react-datepicker__navigation--previous {
      transform: rotate(90deg);
    }
    .react-datepicker__navigation-icon{
      &::before{
        display: none;
      }
    }
  }  
}

// datepicker css

.react-datepicker__tab-loop {
  position: relative;
  .react-datepicker-popper {
    // transform: none !important;
    // width: 100% !important;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-color) !important;
  border-radius: 50% !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.react-datepicker {
  font-family: Public Sans,sans-serif !important;
  font-size: 1.2rem !important;

  .react-datepicker__header {
    padding: 1.4rem;
    border-bottom: 0.1rem solid #ececec;

    .dark-theme & {
      border-color: var(--border-light-color);
    }
  }

  .react-datepicker__year--container {
    .react-datepicker__header {
      font-size: 1.4rem;
      text-align: center;
      color: var(--heading-text);
      padding: 1.6rem 1.4rem 1.3rem 1.4rem;
    }
  }
}

.react-datepicker__year-text {
  line-height: 2.7rem;
}



.auto-width {
  width: auto !important;
}

.radio-group-wrapper {
  display: flex;

  .marital-status {
    margin-top: 0;
    margin-left: 3rem;
  }
}

.employment-sidebar{
  .form-row-wrapper{
    .form-col{
      .react-datepicker{
        border: 0.1rem solid #ececec !important;
        overflow: hidden;
      }
    }
  }
}