.site-footer {
  background-color: #0b2e58;
  .footer-lower-part {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.08);
    .footer-lower-content-wrapper {
      padding: 3.4rem 0 3.9rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      .copy-right {
        ul {
          margin-bottom: 1.7rem;
          line-height: 0;
          li {
            position: relative;
            display: inline-block;
            font-size: 1.4rem;
            line-height: 1.2;
            color: #4f4f4f;
            font-weight: 400;
            margin-right: 1.5rem;
            margin-bottom: 0.5rem;
            &:last-child {
              margin-right: 0;
            }
            &:not(:last-child) {
              a {
                &::after {
                  content: '';
                  position: absolute;
                  left: auto;
                  top: 50%;
                  transform: translateY(-50%);
                  bottom: auto;
                  right: -1.1rem;
                  width: 0.3rem;
                  height: 0.3rem;
                  background-color: #9fd9ff;
                  opacity: 0.8;
                }
              }
            }
            a {
              display: inline-block;
              color: #a9c5d7;
              font-weight: 400;
            }
          }
        }
        p {
          color: #9fd9ff;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.2;
          opacity: 0.8;
          a {
            color: #9fd9ff;
            font-size: inherit;
          }
        }
      }
      .social-icons {
        ul {
          padding-left: 2.5rem;
          li {
            margin: 0.5rem 2rem 0 0;
            display: inline-block;
            &:last-child {
              margin-right: 0;
            }
            a {
              display: inline-block;
              transition: 0.3s;
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
