.job-list {
  margin-top: 2rem;
  > div{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -20px;
    // justify-content: center;
    .job-card{
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 0;
      padding: 0 10px 20px;
      box-shadow: none;
      background-color: #ffffff;
      @include bp(md-max){
        flex:0 0 50%;
        max-width: 50%;
      }
      @include bp(xs-max){
        flex:0 0 100%;
        max-width: 100%;
      }
      .p-card-body{
          border-radius: 8px;
          transition: all 0.3s ease;
          cursor: pointer;
          background-color: #f8f8f8;
          height: 100%;
          padding: 2rem;
      }
    }
    .loading-container{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.job-card {
  background: #ffffff;
  margin-bottom: 2rem;
  // min-height: 250px;
}

.job-virtual-scroller {
  height: calc(100vh - 200px);

  .p-virtualscroller-content {
    padding: 20px;
  }
}

.job-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #f8f8f8;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .p-card-title {
    // color: #333;
    color: #0b2e58;
    font-size: 1.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .p-card-content {
    padding: 0;

    p {
      margin-bottom: 8px;
      color: #555;
      line-height: 1.4;
      &:last-child{
        margin-bottom: 0;
      }
      strong {
        color: #333;
      }
    }
  }
}

.job-description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 15em; /* Adjust based on font size */
}
