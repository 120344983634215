// Header's css
.site-header {
  padding: 1.8rem 0;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  transition: 0.5s;
  &.sticky-header {
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.11);
    transition: 0.3s;
    background: rgba(11, 46, 88, 0.9);
    padding: 0;
  }
  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: 0 2rem;
    transition: 0.3s;
    @include bp(sm-max){
      padding: 0 1.5rem;
    }
    .logo-wrapper{
      .white-logo{
        width: 24rem;
        @include bp(lg-max){
          width: 20rem;
        }
        @include bp(smd-max){
          width: 16rem;
        }
      }
    }
    .navigation-wrapper {
      .main-nav {
        .menu-navigation {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          li {
            margin-right: 4rem;
            @include bp(lg-max){
              margin-right: 3rem;
            }
            @include bp(sm-max){
              margin-right: 2rem;
            }
            @include bp(xxs-max){
              margin-right: 1rem;
            }
            
            a {
              &:not(.btn) {
                font-size: 1.5rem;
                padding: 2.4rem 0;
                display: inline-block;
                line-height: 1.25;
                font-weight: 400;
                color: #fff;
                text-transform: uppercase;
                text-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.6);
                &::after {
                  content: '';
                  position: absolute;
                  right: 0;
                  width: 100%;
                  bottom: 0;
                  height: 0;
                  background-color: #fff;
                  left: 0;
                }
              }
              &.yellow-btn{
                text-transform: uppercase;
                padding: 0.75rem 1.94rem;
                @include bp(xxs-max){
                  min-width: auto;
                  padding: 0.75rem 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}


// toast message css
.p-toast {
  max-width: 36rem;
  @include bp(sm-max) {
    max-width: 28rem;
  }
  .p-toast-message {
    .p-toast-message-content {
      .p-toast-summary {
        &:empty {
          + .p-toast-detail {
            margin-top: 0;
          }
        }
      }
    }
  }
}