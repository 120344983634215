.pending-badge {
  background-color: #fff1e2 !important;
  color: #333333 !important;
}

.schedule-badge {
  background-color: #defdff !important;
  color: #333333 !important;
}

.complete-badge {
  background-color: #e2f7e8 !important;
  color: #333333 !important;
}

.reschedule-badge {
  background-color: #daf2ff !important;
  color: #333333 !important;
}

.cancel-badge {
  background-color: #ffeeef !important;
  color: #333333 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.legends {
  position: relative;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
  @include bp(xxs-max){
    justify-content: flex-start;
  }
  span {
    display: inline-block !important;
    height: 1rem !important;
    width: 1rem !important;
    border-radius: 50%;
    margin-right: 0.6rem;
  }
}

.legend-label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--heading-text);
}

.pending-label {
  background-color: #fff1e2 !important;
}

.schedule-label {
  background-color: #defdff !important;
}

.complete-label {
  background-color: #e2f7e8 !important;
}

.reschedule-label {
  background-color: #daf2ff !important;
}

.cancel-label {
  background-color: #ffeeef !important;
}

.interview-container {
  position: relative;
  .interview-page-wrapper{
    background-color: var(--surface-a);
    border-radius: 1rem;
    overflow: hidden;
    .white-box{
      box-shadow: unset;
      border-radius: 0;
      .p-datatable-wrapper {
        min-height: calc(100vh - 24.9rem);
      }
    }
  }
  .custom-table-wrapper {
    padding: 5rem 0 0 0;
  }

  .p-datatable-header {
    display: none;
  }
  .p-paginator-bottom{
    padding: 1.5rem;   
  }
  .p-paginator{
    .p-dropdown{
      height: 4rem;
      .p-dropdown-trigger{
        width: 3.2rem;
        .p-dropdown-trigger-icon{
          height: auto;
          width: 1.2rem;
          right: 0;
          path{
            fill:var(--heading-text);
          }
        }
      }
    }
    .p-paginator-pages {
      .p-paginator-page{
        border-radius: 0.5rem;
        margin: 0 0.3rem;
      }
    }
    .p-paginator-next , .p-paginator-prev{
      border-radius: 0.5rem;
      svg{
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
  .p-badge{
    height: 100%;
    line-height: 1.4;
  }
  .p-datatable{
    &.p-datatable-sm{
      .p-datatable-tbody{
        > tr{
          > td{
            vertical-align: middle;
            white-space: nowrap;
          }
        }
      }
    }
  } 
  .p-datatable-table{
    table-layout: auto;
  } 
}
.white-box{
  .p-paginator-bottom{
    padding: 1.5rem;   
  }
  .p-paginator{
    .p-dropdown{
      height: 4rem;
      .p-dropdown-trigger{
        width: 3.2rem;
        .p-dropdown-trigger-icon{
          height: auto;
          width: 1.2rem;
          right: 0;
          path{
            fill:var(--heading-text);
          }
        }
      }
    }
    .p-paginator-pages {
      .p-paginator-page{
        border-radius: 0.5rem;
        margin: 0 0.3rem;        
        font-weight: 600;          
        &.p-highlight{
          background: #FFE2C3 !important;  
          color: #FA880A;
          &:hover{
            background: rgb(250, 133, 3, 0.2) !important;
          }
        }
      }
    }
    .p-paginator-next , .p-paginator-prev{
      border-radius: 0.5rem;
      svg{
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
  .p-badge{
    height: 100%;
    line-height: 1.4;
  }
  .p-datatable{
    .p-chip{
      .p-chip-text{
        text-transform: capitalize;
      }
    }
    &.p-datatable-sm{
      .p-datatable-tbody{
        > tr{
          > td{
            vertical-align: middle;
            // white-space: nowrap;    
            &.name-col{
              text-transform: capitalize;
            }        
          }
        }
      }
    }
  }
}