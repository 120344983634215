.error-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.error-note {
  color: red;
}

.submit-box {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.apply-job-header {
  font-size: 22px;
}
.job-table-wrapper {
  table {
    table-layout: auto;
    td,
    th {
      white-space: nowrap;
    }
    .apply-job-btn {
      .pi {
        color: #424242;
      }
    }
  }
}
