.authentication-wrapper {
  .authentication-inner {
    .login-container {
      .gender {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.2rem;
        margin-left: 1.6rem;
        padding: 0.6rem;

        input[type='radio'] {
          border-radius: 50% !important;
        }

        .male > .label {
          margin-left: 0.8rem;
        }
        .female > .label {
          margin-left: 0.8rem;
        }
      }
    }
  }
}
