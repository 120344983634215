// form grid
.form-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.2rem -2.4rem;

  &.form-row-wrap {
    max-width: 52rem;
  }

  @include bp(sm-max) {
    margin-bottom: -2rem;
  }

  .form-col {
    padding: 0 1.2rem;
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 2rem;

    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
    &.form-col-3 {
      max-width: 33.33%;
      flex: 0 0 33.33%;
    }
    &.has-full-width {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .form-row-wrapper {
      padding: 0;
      margin: 0 -0.8rem -2rem;
      .form-col {
        padding: 0 0.8rem;
      }
    }

    &.full-width {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .form-group-outer {
      position: relative;

      .row-wrapper {
        margin: 0 -0.8rem 1.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .custom-form-group {
          padding: 0 0.8rem;
          flex: 1;

          .p-error {
            left: 0.8rem;
          }
        }

        .icon-wrapper-outer {
          flex: 0 0 5.6rem;
          max-width: 5.6rem;
          padding: 0 0.8rem;
        }

        .minus-btn {
          height: 4rem;
          width: 4rem;
          line-height: 4rem;
          box-shadow: none !important;
          border-radius: 0.5rem;
          border: 0.2rem solid #ececec;
          display: flex;
          align-items: center;
          justify-content: center;
          .dark-theme & {
            border-color: #2c2a40;
            color: #767992;
          }
        }
      }

      &.upload-document-wrapper {
        .gray-btn {
          color: var(--secondary-color);
          box-shadow: none !important;
          .dark-theme & {
            background: #26225a;
            border: #26225a;
            color: #e4e6f4;
          }
        }
      }

      .p-error {
        font-size: 1.2rem;
        position: absolute;
        top: calc(100% + 0.1rem);
        left: 0;
        white-space: nowrap;
      }

      .field {
        margin: 0;
      }
    }
  }

  .form-col-2 {
    padding: 0 1.2rem;
    max-width: 33.33%;
    flex: 0 0 33.33%;
    margin-bottom: 2rem;

    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }

    &.full-width {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .form-group-outer {
      position: relative;

      .p-error {
        font-size: 1.2rem;
        position: absolute;
        top: calc(100% + 0.1rem);
        left: 0;
      }
    }
  }
}
.p-dialog .form-row-wrapper .form-col .form-group-outer .p-error {
  white-space: normal;
}

.form-box-wrapper {
  .card-title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.16;
    margin-bottom: 1.6rem;
    color: var(--heading-text);
    text-transform: capitalize;

    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
}

// form group
.custom-form-group {
  position: relative;

  //
  .salary-wrapper {
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .p-inputgroup-addon {
      padding: 0 0.75rem;
    }
  }

  .p-avatar {
    width: 1rem;
    height: 1rem;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .p-invalid {
      border-color: #e24c4c !important;
    }
  }

  .field {
    margin: 0;
  }

  .wrapper-text {
    display: flex;
    align-items: center;
    gap: 1rem;

    label {
      margin: 0;
    }
  }

  .pi-clock {
    position: absolute;
    top: 0.2rem;
    right: 0;
    margin: 0 !important;
    color: var(--primary-color) !important;
    cursor: pointer;
  }

  label {
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
    color: var(--heading-text);

    .dark-theme & {
      color: var(--title-dark-text) !important;
    }

    &.hide-label {
      display: none;
    }
  }

  input,
  textarea {
    height: 4rem;
    line-height: 4rem;
    border-radius: var(--border-radius);
    padding: 0 1rem;
    border: 0.2rem solid var(--light-gray);
    outline: 0;
    box-shadow: none;
    font-size: 1.4rem;
    color: var(--black2);
    font-weight: 400;
    box-sizing: border-box;
    font-family: var(--font-family);
    border-radius: 0.5rem !important;
    .dark-theme & {
      color: #e4e6f4;
      border-color: #2c2a40;
    }
    background-color: transparent !important;
    &:enabled{
      &:hover, &:focus{
        border-color: var(--secondary-color);
      }
    }
    &:focus {
      border-color: var(--primary-color);

      &::placeholder {
        opacity: 0 !important;
      }

      &::-webkit-input-placeholder {
        opacity: 0 !important;
      }

      &:-moz-placeholder {
        opacity: 0 !important;
      }

      &::-moz-placeholder {
        opacity: 0 !important;
      }

      &:-ms-input-placeholder {
        opacity: 0 !important;
      }
    }
    &:hover {
      border-color: var(--secondary-color);
    }
    &::placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      transition: all 0.15s;

      .dark-theme & {
        color: #767992;
      }
    }

    &::-webkit-input-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;

      .dark-theme & {
        color: #767992;
      }
    }

    &:-moz-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;

      .dark-theme & {
        color: #767992;
      }
    }

    &::-moz-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;

      .dark-theme & {
        color: #767992;
      }
    }

    &:-ms-input-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;

      .dark-theme & {
        color: #767992;
      }
    }
  }

  textarea {
    line-height: 1.3;
    padding: 1rem;
    height: 8rem;
    resize: none;
  }

  .p-multiselect {
    height: 4rem;
    width: 100%;
    line-height: 30.8rem;
    border-radius: var(--border-radius);
    padding: 0 1rem;
    border: 0.2rem solid var(--light-gray);
    box-shadow: none !important;
    font-size: 1.4rem;
    color: var(--black2);
    font-weight: 400;

    .dark-theme & {
      background-color: transparent;
      color: #e4e6f4;
      border-color: #2c2a40;

      &:hover {
        border-color: var(--primary-color);
      }
    }

    .p-multiselect-trigger {
      position: absolute;
      right: -0.8rem;
      top: 0;
      height: 100%;
      color: var(--heading-text);

      .dark-theme & {
        color: var(--input-dark-text);
      }

      .pi {
        font-size: 1.2rem;
      }
    }

    .p-multiselect-label {
      color: var(--light-gray2);
      font-size: 1.4rem;

      @include bp(sm-max) {
        font-size: 1.4rem;
      }
      &.p-placeholder {
        .dark-theme & {
          color: #767992;
        }
      }
      .dark-theme & {
        color: #e4e6f4;
      }
    }

    .p-multiselect-label-container {
      margin-top: -0.1rem;

      .p-multiselect-label {
        padding: 0 1rem 0 0;

        .p-multiselect-token {
          padding: 0 0.8rem;
          height: 3rem;
          line-height: 3rem;
          color: var(--black2);
          background-color: var(--surface-100);
          font-size: 1.4rem;

          .p-multiselect-token-label {
            min-height: 1rem;
          }

          .dark-theme & {
            color: var(--border-gray);
            background-color: #26225a;
          }
        }
      }
    }

    &:focus {
      border-color: var(--primary-color);
    }

    &.p-inputwrapper-filled {
      .p-multiselect-trigger {
        color: var(--black2);

        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }
  }
  &.autocomplete {
    .p-autocomplete {
      .p-inputtext {
        height: auto;
      }
    }
  }
  .p-autocomplete {
    position: relative;
    width: 100%;

    &::before {
      position: absolute;
      top: 0.1rem;
      bottom: 0.1rem;
      left: 0.1rem;
      width: 1.5rem;
      background-color: transparent;
      content: '';
      border-radius: 0.5rem;
    }

    &::after {
      position: absolute;
      top: 0.1rem;
      bottom: 0.1rem;
      right: 0.1rem;
      width: 1.5rem;
      background-color: transparent;
      content: '';
      border-radius: 0.5rem;
    }

    .p-inputtext {
      padding: 0;
      border: 0;
      box-shadow: none;
      min-height: 4rem;
      height: 4rem;
      line-height: 4rem;
      border-radius: var(--border-radius);
      padding: 0 3.5rem 0 1rem;
      border: 0.2rem solid var(--light-gray);
      box-shadow: none !important;
      font-size: 1.4rem;
      color: var(--black2);
      font-weight: 400;
      display: block;
      // white-space: nowrap;
      cursor: default;
      &:enabled{
        &:focus{
          border-color: var(--primary-color);
        }
      }
      // overflow-x: auto;
      @include bp(sm-max) {
        font-size: 1.5rem;
      }

      &::-webkit-scrollbar {
        height: 0.4rem;
        border-radius: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--gray-rgba);
        border-radius: 0.4rem;

        .dark-theme & {
          background-color: var(--white-rgba);
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--light-gray3);
        outline: none;
        border-radius: 0.4rem;
      }

      .dark-theme & {
        border-color: #2c2a40;
        color: var(--input-dark-text);
      }

      .p-autocomplete-input-token {
        width: 100%;
        padding: 0;
        border: 0;
        box-shadow: none;
        order: 1;

        input {
          width: 100%;
          box-shadow: none;
          height: inherit;
          line-height: 1;
          box-shadow: none;
          border: 0;

          .dark-theme & {
            color: var(--input-dark-text);
          }
        }
      }
    }

    .p-autocomplete-token {
      padding: 0 0.8rem;
      height: 3rem;
      line-height: 3rem;
      color: var(--black2) !important;
      background-color: var(--surface-100) !important;
      font-size: 1.4rem;

      .p-autocomplete-token-label {
        min-height: 1rem;
      }

      .dark-theme & {
        color: var(--border-gray);
        background-color: var(--surface-body);
      }
    }

    &:focus {
      border-color: var(--primary-color);
    }
  }

  > textarea {
    + p {
      font-size: 1.2rem;
    }
  }
  .tox .tox-toolbar__primary {
    .dark-theme & {
      border-bottom: 0.1rem solid #2c2a40;
      background: none;
      background-color: var(--dark-boxes);
    }
  }
  .tox-tinymce,
  .tox .tox-menubar + .tox-toolbar,
  .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary,
  .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type),
  .tox .tox-statusbar {
    .dark-theme & {
      border-color: #2c2a40;
    }
  }
  .tox .tox-menubar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary,
  .tox .tox-edit-area__iframe,
  .tox .tox-statusbar {
    .dark-theme & {
      background-color: var(--dark-boxes);
      p {
        color: var(--title-dark-text) !important;
      }
    }
  }
  .tox .tox-mbtn,
  .tox .tox-tbtn,
  .tox .tox-tbtn:hover,
  .tox .tox-tbtn,
  .tox .tox-tbtn:hover,
  .tox .tox-statusbar,
  .tox .tox-statusbar a,
  .tox .tox-statusbar__path-item,
  .tox .tox-statusbar__wordcount {
    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
  .tox .tox-tbtn svg,
  .tox .tox-tbtn:hover svg,
  .tox .tox-tbtn:disabled svg,
  .tox .tox-tbtn:disabled:hover svg {
    .dark-theme & {
      fill: var(--title-dark-text);
    }
  }
  .p-invalid .custom-create-select__control{
    border-color: #e24c4c !important;
  }
  .custom-create-select__control {
    border-radius: 0.5rem !important;
    border: 0.2rem solid var(--light-gray);
    min-height: 4rem;
    box-shadow: unset;
    &:hover {
      border-color: var(--secondary-color);
    }
    input {
      height: 100%;
      color: var(--black2) !important;
      font-family: var(--font-family) !important;
      font-size: 1.4rem !important;
      font-weight: 400 !important;
      border-radius: 0 !important;
    }
    .custom-create-select__value-container {
      // padding: 0.3rem 1rem;
      padding: 0 0.5rem;
      cursor: pointer;
      .custom-create-select__multi-value {
        background-color: var(--surface-100);
        height: 3rem;
        line-height: 3rem;
        // margin: 0.5rem 0.5rem 0 0;
        border-radius: 0.5rem;
        padding: 0 0.8rem;
        color: var(--black2);
        font-size: 1.4rem;
        text-transform: capitalize;
        .custom-create-select__multi-value__label {
          padding: 0;
          min-height: 1rem;
          color: var(--black2);
          font-size: 1.4rem;
        }
        .custom-create-select__multi-value__remove {
          padding: 0;
          position: relative;
          display: inline-block;
          margin-left: 0.5rem;
          cursor: pointer;                    
          background-image: url('../../images/grid-filter-close-icon.svg');
          background-repeat: no-repeat;
          background-position: center;
          width: 1.6rem;
          min-width: 1.6rem;
          svg {
            display: none;
          }
        }
      }
      .custom-create-select__placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        transition: all 0.15s;
        font-size: 1.4rem;
        padding: 0;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      .custom-create-select__input-container {
        padding: 0;
        margin: 0;
        display: block;
        &::after {
          display: none;
        }
        .custom-create-select__indicator {
          color: var(--heading-text);
        }
      }
    }
  }
  .custom-create-select__menu {
    padding: 0;
    margin: 0;
    background-color: var(--surface-a);
    border-radius: 0.6rem;
    box-shadow: 0 0.2rem 1.2rem 0 #0000001a;
    .custom-create-select__menu-list {
      padding: 0;
      .custom-create-select__menu-notice {
        color: var(--input-dark-text);
        padding: 0.75rem 1.25rem;
        font-size: 1.4rem;
      }
      .custom-create-select__option {
        padding: 1rem;
        font-size: 1.4rem;
        background-color: #fff !important;
        color: var(--black2);
        cursor: pointer;
        transition: all 0.3s;
        text-transform: capitalize;
        &.custom-create-select__option--is-selected {
          color: var(--primary-color);
          background: var(--surface-100) !important;
        }
        &:hover {
          background: var(--surface-100) !important;
        }
      }
    }
  }
  .custom-create-select__dropdown-indicator {
    color: var(--heading-text);
    height: 100%;
    cursor: pointer;
    background-image: url('../../images/dropdown-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.4rem;
    width: 3.6rem;
    svg{
      display: none;
    }
  }
  .custom-create-select__indicator-separator {
    color: var(--light-gray);
  }
  .custom-create-select__clear-indicator {
    cursor: pointer;
  }
}
.dark-theme .custom-form-group .tox .tox-mbtn,
.dark-theme .custom-form-group .tox .tox-tbtn {
  &:hover {
    background: var(--link-hover-bg) !important;
    color: var(--title-dark-text) !important;
  }
}

.field > label {
  margin-bottom: 0.6rem;
  line-height: 1.2;
}

.p-multiselect-panel {
  background-color: var(--surface-a);
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1);

  .dark-theme & {
    background-color: var(--dark-boxes);
    box-shadow: 0 0.2rem 1.2rem 0 rgb(0 0 0 / 25%);
  }

  .p-multiselect-header {
    padding: 1rem;
    border: 0;

    .dark-theme & {
      background-color: var(--dark-boxes);
    }

    .p-multiselect-filter-container {
      .p-inputtext {
        height: 4rem;
        line-height: 4rem;
        border-radius: var(--border-radius);
        padding: 0 3rem 0 1rem;
        border: 0.1rem solid var(--border-gray);
        box-shadow: none !important;
        font-size: 1.6rem;
        color: var(--black2);
        font-weight: 400;

        @include bp(sm-max) {
          font-size: 1.5rem;
        }

        .dark-theme & {
          color: var(--input-dark-text);
          border-color: #2c2a40;
        }

        &:focus {
          border-color: var(--primary-color);
        }
      }

      .p-multiselect-filter-icon {
        color: var(--light-gray2);

        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }

    .p-multiselect-close {
      color: var(--light-gray2);
      box-shadow: none !important;

      .dark-theme & {
        color: var(--input-dark-text) !important;
      }
    }
  }

  .p-multiselect-items-wrapper {
    ul {
      padding: 0;
    }

    .p-multiselect-empty-message {
      color: var(--input-dark-text) !important;
      font-size: 1.4rem;
    }

    .p-multiselect-item {
      font-size: 1.4rem;
      box-shadow: none !important;
      padding: 1rem;
      white-space: normal;
      word-break: break-all;
      max-width: 34rem;

      @include bp(xxs-max) {
        max-width: inherit;
      }

      .dark-theme & {
        color: var(--input-dark-text);
      }

      &:not(.p-highlight) {
        &:hover {
          background-color: var(--surface-100) !important;

          .dark-theme & {
            background: var(--link-hover-bg) !important;
            color: #e4e6f4 !important;
          }
        }
      }

      &.p-highlight {
        color: var(--primary-color);

        .dark-theme & {
          background-color: var(--surface-body);
        }
      }
    }
  }
}

.p-autocomplete-panel {
  background-color: var(--surface-a);
  box-shadow:
    0 0.3rem 0.9rem 0.1rem rgba(58, 54, 68, 0.03),
    0 0.9rem 0.8rem rgb(46, 43, 54, 0.02),
    0 0.1rem 0.6rem 0.4rem rgb(48, 46, 57, 0.01);

  .dark-theme & {
    background-color: var(--dark-boxes);
    box-shadow: 0 0.2rem 1.2rem 0 rgb(0 0 0 / 10%);
  }

  .p-autocomplete-items {
    padding: 0;

    .p-autocomplete-item {
      font-size: 1.4rem;
      box-shadow: none !important;
      padding: 1.5rem 1.5rem 0;

      &:last-child {
        padding-bottom: 1.5rem;
      }

      .dark-theme & {
        color: var(--input-dark-text);
      }

      &:hover {
        background-color: var(--surface-100) !important;

        .dark-theme & {
          background: var(--link-hover-bg) !important;
          color: var(--title-dark-text) !important;
        }
      }
    }
  }
}

// radio buttons
.radio-list-wrapper {
  display: flex;
  flex-wrap: wrap;

  input {
    opacity: 0;
  }

  .form-group-outer-radiobutton {
    display: inline-flex;
    align-items: center;

    label {
      padding: 0 0 0 0.8rem;
      margin-bottom: 0;
      cursor: pointer;

      .dark-theme & {
        color: var(--input-dark-text);
      }
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.form-btn-wrapper {
  display: flex;
  margin: 2.4rem -2.3rem -1rem;
  padding: 2rem 2.3rem 0;
  border-top: 0.1rem solid var(--light-gray4);

  .dark-theme & {
    border-top-color: var(--border-light-color);
  }

  flex-wrap: wrap;

  @include bp(lgl-max) {
    margin: 2rem -1.5rem 0;
    padding: 2rem 1.5rem 0;
  }

  button {
    &.p-button:not(.gray-btn){      
      &:hover{
        background-color: var(--secondary-color) !important;
        border-color: var(--secondary-color) !important;
      }
    }    
    &:not(:last-child) {
      margin-right: 1.5rem;

      @include bp(xxs-max) {
        margin-right: 0;
      }
    }

    margin-bottom: 1rem;

    @include bp(xxs-max) {
      width: 100% !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

// Color picker css
.color-picker-wrapper {
  display: flex;
  position: relative;
  z-index: 1;

  .p-colorpicker {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    input {
      height: 2.5rem !important;
      width: 2.5rem;
      line-height: 2.5rem !important;
      padding: 0;
      border: none !important;
    }
  }

  .hex-code {
    padding-left: 1rem;
    font-size: 1.6rem;
    color: var(--black2);
    font-weight: 400;

    @include bp(sm-max) {
      font-size: 1.5rem;
    }
  }

  > input {
    padding-left: 6rem;
  }

  &:before {
    position: absolute;
    content: '#';
    font-size: 2rem;
    left: 0.1rem;
    top: 0.1rem;
    bottom: 0.1rem;
    width: 40.8rem;
    background-color: var(--light-gray4);
    border-radius: 0.6rem 0 0 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black2);
    font-weight: 700;

    .dark-theme & {
      background-color: var(--surface-body);
      color: var(--input-dark-text);
    }
  }
}

// dropdown css
.form-col,
.custom-form-group {
  .p-dropdown {
    width: 100%;
    border-color: var(--light-gray);
    height: 4rem;
    &:not(.p-disabled){
      &:hover{
        border-color: var(--secondary-color);
      }
    }
    .dark-theme & {
      border-color: #2c2a40;
    }

    &.p-inputwrapper-filled {
      .p-dropdown-trigger {
        color: var(--black2);
        background-image: url('../../images/dropdown-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.4rem;
        svg{
          display: none;
        }
        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }

    .p-dropdown-label {
      height: 3.7rem;
      line-height: 3.6rem;
      padding: 0 1rem;
      color: var(--black2);
      font-size: 1.4rem;

      .dark-theme & {
        color: #e4e6f4 !important;
        &.p-placeholder {
          color: #767992 !important;
        }
      }
    }

    .p-dropdown-trigger {
      color: var(--heading-text);
      position: absolute;
      height: 100%;
      color: var(--heading-text);
      right: 0.1rem;
      top: 50%;
      transform: translateY(-50%);
      svg {
        height: auto;
        width: 1.2rem;
        right: 0;
      }
    }

    .p-dropdown-clear-icon {
      color: var(--border-gray);
      right: 3rem;

      &.pi-times:before {
        content: '\e90c';
      }
    }

    .dark-theme & {
      background-color: transparent;
      color: var(--title-light-text);

      &:hover {
        border-color: var(--primary-color);
      }

      .p-dropdown-trigger {
        color: var(--input-dark-text);
      }
    }
  }
}

.p-dropdown-panel {
  &.custom-dropdown-panel {
    background-color: var(--surface-a);
    box-shadow:
      0 0.3rem 0.9rem 0.1rem rgba(58, 54, 68, 0.03),
      0 0.9rem 0.8rem rgb(46, 43, 54, 0.02),
      0 0.1rem 0.6rem 0.4rem rgb(48, 46, 57, 0.01);

    .dark-theme & {
      background-color: var(--dark-boxes);
      box-shadow: 0 0.2rem 1.2rem 0 rgb(0 0 0 / 25%);
    }

    .p-dropdown-items {
      .p-dropdown-item {
        font-size: 1.4rem;
        box-shadow: none !important;
        padding: 1rem 1.4rem;
        color: var(--black2) !important;

        @include bp(sm-max) {
          font-size: 1.5rem;
        }

        .dark-theme & {
          color: var(--input-dark-text) !important;
        }

        &:not(.p-highlight) {
          &:hover {
            background: #e9ecef;
            background-color: var(--surface-100) !important;

            .dark-theme & {
              background: var(--link-hover-bg) !important;
              color: #e4e6f4 !important;
            }
          }
        }

        &.p-highlight {
          color: var(--primary-color) !important;

          .dark-theme & {
            background: var(--link-hover-bg) !important;
            color: #e4e6f4 !important;
          }
        }
      }
    }

    .p-dropdown-filter {
      height: 4.5rem;
      line-height: 4.5rem;
      border-radius: var(--border-radius);
      padding: 0 1.5rem;
      border: 0.1rem solid var(--border-gray);
      border-width: 0.1rem !important;
      outline: 0;
      box-shadow: none;
      font-size: 1.6rem;
      color: var(--black2);
      font-weight: 400;

      @include bp(sm-max) {
        font-size: 1.5rem;
      }

      .dark-theme & {
        color: var(--input-dark-text);
      }

      &:focus {
        border-color: var(--primary-color) !important;

        &::placeholder {
          opacity: 0 !important;
        }

        &::-webkit-input-placeholder {
          opacity: 0 !important;
        }

        &:-moz-placeholder {
          opacity: 0 !important;
        }

        &::-moz-placeholder {
          opacity: 0 !important;
        }

        &:-ms-input-placeholder {
          opacity: 0 !important;
        }
      }

      &::placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        transition: all 0.15s;

        .dark-theme & {
          color: var(--title-light-text);
        }
      }

      &::-webkit-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: var(--title-light-text);
        }
      }

      &:-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: var(--title-light-text);
        }
      }

      &::-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: var(--title-light-text);
        }
      }

      &:-ms-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;

        .dark-theme & {
          color: var(--title-light-text);
        }
      }
    }
  }
}

.form-col {
  .p-dropdown {
    .dark-theme & {
      background-color: transparent;
      color: var(--title-light-text);
    }
  }

  &.has-custom-upload {
    label {
      margin-bottom: 0.8rem;
      display: inline-block;
    }
  }
}

// Datepicker Css
body {
  .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    z-index: 2103;
  }
}

.custom-datepicker {
  .MuiInputBase-formControl {
    border: 0;
    padding: 0;

    &::after {
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
      width: 4rem;
      background-repeat: no-repeat;
      background-size: 10.8rem;
      content: '';
      background-position: center;
    }

    input {
      padding: 0 5rem 0 1.5rem !important;
      box-sizing: border-box;
    }
  }

  fieldset {
    border: 0;
    display: none;
  }

  .MuiInputAdornment-root {
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    top: 0;
    height: 100%;
    max-height: inherit;
    z-index: 5;

    .MuiButtonBase-root {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      z-index: 4;
      margin: 0;
      border-radius: 0;

      svg {
        fill: var(--border-gray);

        .dark-theme & {
          fill: var(--input-dark-text);
        }
      }
    }
  }
}

// Upload Document Css
.upload-document-wrapper {
  button {
    margin-right: 1.5rem;
  }

  h5 {
    font-size: 1.3rem;
    color: var(--primary-color);
    margin: 0.8rem 0 !important;
  }
  p {
    color: #333;
    font-family:
      Public Sans,
      sans-serif;
    font-size: 1.2rem;
    line-height: 1.18;
    padding: 0.5rem 0 0;
    width: 100%;
  }
}

// datepicker css
.custom-datepicker-panel {
  .MuiPaper-root {
    box-shadow:
      0 0.3rem 0.9rem 0.1rem rgba(58, 54, 68, 0.03),
      0 0.9rem 0.8rem rgb(46, 43, 54, 0.02),
      0 0.1rem 0.6rem 0.4rem rgb(48, 46, 57, 0.01);
  }

  .dark-theme & {
    .MuiPaper-root {
      background-color: var(--surface-body);
    }
  }

  .MuiCalendarPicker-root {
    .MuiPaper-root {
      box-shadow:
        0 0.3rem 0.9rem 0.1rem rgba(58, 54, 68, 0.03),
        0 0.9rem 0.8rem rgb(46, 43, 54, 0.02),
        0 0.1rem 0.6rem 0.4rem rgb(48, 46, 57, 0.01);
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiButtonBase-root,
    button {
      color: var(--black2);

      .dark-theme & {
        color: var(--title-light-text2);
      }

      &.Mui-disabled {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }

      &:not(.Mui-selected) {
        border-color: var(--black2);

        .dark-theme & {
          color: var(--title-light-text2);
          background-color: transparent;
        }
      }

      &:hover {
        background-color: var(--rgba1) !important;

        .dark-theme & {
          background-color: var(--rgba3) !important;
        }
      }
    }

    .Mui-selected {
      background-color: var(--primary-color) !important;
      color: var(--surface-a);

      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--surface-a);

        .dark-theme & {
          background-color: var(--primary-color) !important;
          // color: var(--surface-a) !important;
          color: var(--title-light-text2);
        }
      }
    }

    svg {
      color: var(--black2);

      .dark-theme & {
        color: var(--title-light-text2);
      }
    }

    .MuiDayPicker-header {
      .MuiTypography-root {
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
    }

    .MuiPickersCalendarHeader-labelContainer {
      color: var(--black2);

      .dark-theme & {
        color: var(--title-light-text2);
      }
    }

    .MuiDayPicker-slideTransition {
      min-height: 23rem;
    }

    .MuiPaper-root {
      .dark-theme & {
        background-color: var(--surface-body);
      }
    }
  }
}

// custom timepicker
.custom-timepicker {
  .MuiInputBase-formControl {
    border: 0;
    padding: 0;

    input {
      padding: 0 5rem 0 1.5rem !important;
      box-sizing: border-box;
    }
  }

  fieldset {
    border: 0;
  }

  .MuiInputAdornment-root {
    margin: 0;

    .MuiButtonBase-root {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);

      svg {
        fill: var(--border-gray);

        .dark-theme & {
          fill: var(--input-dark-text);
        }
      }
    }
  }
}

.custom-timepicker-panel {
  .MuiPaper-root {
    box-shadow:
      0 0.3rem 0.9rem 0.1rem rgba(58, 54, 68, 0.03),
      0 0.9rem 0.8rem rgb(46, 43, 54, 0.02),
      0 0.1rem 0.6rem 0.4rem rgb(48, 46, 57, 0.01);

    .dark-theme & {
      background-color: var(--surface-body);
    }
  }

  .MuiClock-wrapper {
    .MuiClockNumber-root {
      .dark-theme & {
        color: var(--title-light-text2);
      }
    }
  }

  .MuiClock-clock {
    .dark-theme & {
      background-color: var(--dark-boxes);
    }
  }

  .MuiPickersArrowSwitcher-button {
    .dark-theme & {
      color: var(--title-light-text2);
    }

    &.Mui-disabled {
      opacity: 0.6;
    }
  }

  .MuiClock-root {
    .MuiClockPointer-root {
      background-color: var(--primary-color);
    }

    .MuiClockPointer-thumb {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    .MuiClock-pin {
      background-color: var(--primary-color);
    }

    .MuiButtonBase-root {
      height: 3.5rem;
      width: 3.5rem;

      .MuiTypography-root {
        line-height: 0.98;
      }

      .dark-theme & {
        color: var(--title-light-text2);
      }

      // &.MuiClock-amButton{
      //     background-color: var(--primary-color);
      // }
    }
  }
}

.add-candidate-lpa {
  background-color: inherit;

  .dark-theme & {
    color: #767992 !important;
    border-color: #2c2a40 !important;
  }
}
.MuiButtonBase-root.MuiButton-root {
  text-transform: none;
  font-weight: 500;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  &.outline-btn {
    background-color: transparent;
    border: 0.1rem solid var(--primary-color);
    color: var(--primary-color);
    height: 3rem;
    padding: 0.5rem 1rem;
    box-shadow: none !important;
    font-size: 1.2rem;
    line-height: 1.4rem;

    &:hover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
    }

    .dark-theme & {
      color: var(--light-gray2);
      border-color: #2c2a40;

      &:hover {
        background: #fff;
        color: var(--heading-text);
      }
    }
  }
}

.add-more-link {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--primary-color) !important;
  background: transparent !important;
  border: 0 !important;
  line-height: 1.7rem;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.1rem;
    background: var(--primary-color);
    transition: 0.3s width;
    width: 0;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.react-datepicker__header__dropdown{
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0;
  .react-datepicker__year-dropdown-container,
  .react-datepicker__month-dropdown-container{
    margin: 0;        
    select{
      height: 3rem;
      line-height: 2.8rem;
      padding: 0 1.5rem;
      border: 0.1rem solid #ddd;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      font-family: 'Public Sans';
      width: 100%;
      cursor: pointer;
      background-image: url('../../images/dropdown-arrow.svg');
      background-repeat: no-repeat;
      background-size: 1.3rem;
      background-position: 92% 0.9rem; 
      color: var(--black2); 
      option{
        font-size: 1.4rem;
        &:hover{
          background-color: #ddd;
          color: var(--text-color);
        }
      }
    }
  }
  .react-datepicker__month-dropdown-container{
    flex: 1;
    width: 100%;
  }
  .react-datepicker__year-dropdown-container{
    flex: 0 0 8rem;
    max-width: 8rem;
  }
}

// / Option hover effect /
.react-datepicker__month-select option:hover {
  background-color: red !important;
}

.custom-form-group input:disabled:hover,
.custom-form-group textarea:disabled:hover {
    border-color: var(--light-gray) !important;
}
.field-checkbox{
  label{
    cursor: pointer;
    color: var(--heading-text);
    font-size: 1.3rem;
    line-height: 1.2;
    margin-top: 0.1rem;
  }
  .p-checkbox {
    height: 1.8rem;
    width: 1.8rem;
    position: relative;    
    input{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      appearance: none !important;
      border: none;   
    }
    &.p-highlight{
      .p-checkbox-box{
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }      
    }
    .p-checkbox-box{
      border: 0.2rem solid var(--light-gray);   
      height: 1.8rem;
      width: 1.8rem;   
      border-radius: 0.3rem;
      transition: all 0.3s;
      background-color: #fff;
    }
  }
}