.p-datatable-wrapper {
  min-height: calc(100vh - 32.5rem);

  @include bp(sm-max) {
    min-height: inherit;
  }

  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  .text-no-wrap {
    white-space: nowrap;
  }

  .text-bold {
    font-weight: 400;
  }

  .name-col {
    min-width: 15rem;
    min-height: 4rem;
  }
}

.table-header {
  .ml-auto {
    display: flex;
    align-items: center;
  }

  .ml-auto i {
    font-size: 1.1rem;
    cursor: pointer;
  }
}

.p-datatable-table {
  table-layout: fixed;
  width: 100%;
  font-size: 1.3rem;
  margin: 0 0 0.2rem;
  tr {
    td {
      &:last-child{
        width: 0.1rem;
        white-space: nowrap;
      }
      i {
        font-size: 1.1rem;
        cursor: pointer;
      }
      @include bp(sm-max) {
        font-size: 1.4rem;
      }

      .pi-file-pdf {
        font-size: 1.7rem;
        color: #9085ff;
      }

      .p-inputswitch {
        height: 2.4rem;
        width: 4rem;

        .p-inputswitch-slider {
          &::before {
            height: 1.6rem;
            width: 1.6rem;
            transform: translateY(-50%);
            margin: 0;
            left: 0.5rem;
          }
        }

        &.p-inputswitch-checked {
          .p-inputswitch-slider {
            &::before {
              left: auto;
              right: 0.5rem;
            }
          }
        }
      }

      .primary-icon-btn {
        &.action-popup-button {
          &:focus {
            background-color: var(--white2);
          }
        }
      }

      .test-color-pallette {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .color-badge {
          height: 1.2rem;
          width: 1.2rem;
          border-radius: 50%;
          background-color: #cac9cc;
          display: block;
          margin-right: 0.8rem;
        }
      }
    }
  }

  .p-datatable-header {
    background: #ffffff;
  }

  .p-datatable-emptymessage {
    td {
      text-align: center !important;
    }
  }
}



.p-paginator {
  padding: 1.5rem;
  justify-content: flex-start;

  .dark-theme & {
    background-color: var(--dark-boxes);
    border-color: var(--dark-boxes);
  }

  @include bp(sm-max) {
    flex-wrap: wrap;
    padding: 1.5rem 1.5rem;

    .p-paginator-current {
      width: 100%;
      line-height: 1.3;
      margin-bottom: 0.5rem !important;
    }
  }

  .p-paginator-current {
    line-height: 1.2;
  }

  .p-paginator-first,
  .p-paginator-pages .p-paginator-page,
  .p-paginator-prev,
  .p-paginator-page,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    color: var(--text-color);
    font-size: 1.3rem;
    margin: 0 0.3rem;

    .dark-theme & {
      color: var(--title-dark-text);
      background-color: #131129;

      &.p-highlight {
        background-color: rgba(115, 103, 240, 0.4);

        &:hover {
          background-color: rgba(115, 103, 240, 0.2) !important;
        }

        &:focus {
          background-color: rgba(115, 103, 240, 0.4) !important;
        }
      }

      &:hover {
        color: var(--light-gray4) !important;
        background-color: darken(#25293c, 5) !important;
      }
    }

    &.p-disabled {
      background-color: rgba(47, 43, 61, 0.08);
      color: rgb(47, 43, 61);
      opacity: 0.45;

      .dark-theme & {
        color: darken(#e8e8e8, 5) !important;
        background-color: darken(#2f3349, 5) !important;
      }
    }
  }

  .p-dropdown {
    height: 4rem;
    align-items: center;
    margin-right: auto;
    border-color: #ececec;
    border-radius: 0.5rem;
    &:not(.p-disabled){
      &:hover, &:focus, &.p-focus{
        border-color: var(--secondary-color);
      }
    }    
    .dark-theme & {
      background-color: var(--dark-boxes);
      border-color: #2c2a40;
    }

    @include bp(sm-max) {
      margin-left: 0 !important;
    }

    &.p-inputwrapper {
      .p-dropdown-label {
        &.p-inputtext {
          padding: 0 0 0 1rem;
          line-height: 3rem;
          height: 3rem;
          font-size: 1.4rem;
          color: var(--light-gray3);

          .dark-theme & {
            color: #767992;
          }
        }
      }
    }

    .p-dropdown-trigger {
      width: 2rem;
      color: var(--light-gray3);

      .dark-theme & {
        color: #767992;
      }

      .pi {
        font-size: 1.2rem;
        right: 0;
        color: var(--heading-text);
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
    }
  }
}

.p-datatable-header {
  .dark-theme & {
    background-color: var(--dark-boxes) !important;
  }

  .table-header {
    flex-wrap: wrap;
    gap: 2.5rem;
    @include bp(xxs-max){
      flex-direction: column-reverse;
      gap: 1rem;
      align-items: flex-end;
    }

    .table-actions-left {
      // display: flex;
      // align-items: center;
      // margin: 0;
      // padding-right: 0;
      // gap: 1rem;
      @include bp(xxs-max){
        width: 100%;
      }

      // @include bp(lg-max) {
      // }

      // @include bp(sm-max) {
      //   // margin: 1.5rem 0 0;
      //   padding-right: 0;
      // }

      // @include bp(xs-max) {
      //   flex-wrap: wrap;
      // }

      .input-outer-wrapper {
        &:not(:empty) {
          margin-right: 1.5rem;

          @include bp(lg-max) {
            margin-right: 1rem;
          }

          // @include bp(sm-max){
          //   flex-grow: 1;
          // }
          @include bp(xs-max) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        .search-input {
          @include bp(lg-max) {
            width: 100%;
          }
        }
      }

      // margin-right: 24px;
      // @include bp(sm-max) {
      //   order: 2;
      //   width: 100%;
      //   margin-right: 0;
      // }
    }

    .experienceFilter {
      width: auto !important;
      min-width: auto !important;

      .p-button {
        border: 0.2rem solid var(--light-gray);
        box-shadow: none !important;
        font-size: 1.4rem;
        color: var(--light-gray2);
        background: transparent;
        padding: 0.8rem 1rem;
        line-height: 3.9rem;

        @include bp(xs-max) {
          width: 100%;
          justify-content: space-between;
        }

        .pi {
          margin-left: 1.4rem;
          margin-top: 0.1rem;
          font-size: 1.2rem;
          color: var(--heading-text);
          .dark-theme & {
            color: var(--input-dark-text);
          }
        }

        &:hover {
          border-color: #6366f1;
        }

        .dark-theme & {
          color: #767992;
          border-color: #2c2a40;

          &:hover {
            border-color: var(--primary-color);
          }
        }
      }
    }

    .table-actions-dropdowns {
      margin: 0rem !important;

      // flex: 0 0 100%;
      // max-width: 100%;
      // @include bp(lg-max) {
      //   flex: 0 0 100%;
      //   max-width: 100%;
      //   justify-content: space-between;
      //   margin-top: 1.5rem;
      // }
      @include bp(xs-max) {
        width: 100%;
      }

      @include bp(xxs-max) {
        margin: 0 !important;
      }

      @include bp(xxxl-min) {
        margin-bottom: 0;
      }

      .dropdowns-inner-wrapper {
        // max-width: 680px;
        display: flex;
        align-items: center;

        // justify-content: space-between;
        // @include bp(lg-max){
        //   justify-content: space-between;
        // }
        @include bp(xs-max) {
          // margin-top: 1.5rem;
          justify-content: space-between;
        }

        @include bp(sm-max) {
          // margin-top: 1.5rem;
          justify-content: space-between;
        }

        @include bp(xxs-max) {
          flex-wrap: wrap;
          margin-top: 0;
        }
      }

      // @include bp(smd-max) {
      //   flex: 0 0 100%;
      //   max-width: 100%;
      // }
      @include bp(sm-max) {
        order: 3;
        min-width: auto;
      }

      .custom-form-group {
        // flex: 0 0 calc(50% - 1.2rem);
        // max-width: calc(50% - 1.2rem);
        min-width: 18.5rem;

        @include bp(lg-max) {
          width: 15.3rem;
          max-width: 15.3rem;
          min-width: auto;
        }

        // @include bp(lg-max){
        //   flex: 0 0 calc(50% - 1.2rem);
        //   max-width: calc(50% - 1.2rem);
        // }
        .p-dropdown-label {
          @include bp(lg-max) {
            padding-right: 0.5rem;
            padding-left: 1rem;
          }
        }

        .p-dropdown-trigger {
          @include bp(lg-max) {
            width: 30.2rem;
          }
        }

        &:not(:empty) {
          margin-right: 0;

          @include bp(lg-max) {
            margin-right: 0;
          }

          @include bp(xs-max) {
            margin-right: 0;
          }
        }

        &:empty {
          margin: 0 !important;
        }

        &:last-child {
          margin-right: 0;
        }

        // @include bp(smd-max) {
        //   flex: 0 0 calc(50% - 6px);
        //   max-width: calc(50% - 6px);
        //   &:not(:empty) {
        //     margin-top: 1.2rem;
        //   }
        // }
        @include bp(xs-max) {
          flex: 0 0 calc(50% - 1.2rem);
          max-width: calc(50% - 1.2rem);
        }

        @include bp(xs-max) {
          flex: 0 0 100%;
          max-width: 100%;

          &:not(:empty) {
            margin-top: 0;
          }
        }
      }
    }

    @include bp(sm-max) {
      flex-wrap: wrap;
    }

    .p-input-icon-left {
      @include bp(sm-max) {
        order: 2;
        display: block;
        width: 100%;
      }

      &.search-input {
        .search-icon{
          margin-top: 0;
          transform: translateY(-50%);
          color: var(--light-gray2);
          font-size: 1.4rem;
          padding-left: 0;
          position: absolute;
          top: 46%;
          left: 0.9rem;

          .dark-theme & {
            color: var(--title-light-text);
          }
        }

        .p-inputtext {
          width: 38.5rem;
          line-height: 4.7rem;
          padding: 0 1rem 0 2.8rem;

          // transition: all 0.5s;
          @include bp(sm-max) {
            font-size: 1.5rem;
          }

          .dark-theme & {
            color: #e4e6f4;
            border-color: #2c2a40;
          }

          &:focus {
            border-color: var(--secondary-color);
            &::-webkit-input-placeholder {
              opacity: 0 !important;
            }

            &:-moz-placeholder {
              opacity: 0 !important;
            }

            &:-ms-input-placeholder {
              opacity: 0 !important;
            }
          }
          &:hover {
            border-color: var(--secondary-color);
          }
          &::-webkit-input-placeholder {
            color: var(--light-gray2);
            opacity: 1 !important;

            .dark-theme & {
              color: #767992;
            }
          }

          &:-moz-placeholder {
            color: var(--light-gray2);
            opacity: 1 !important;

            .dark-theme & {
              color: #767992;
            }
          }

          &::-moz-placeholder {
            color: var(--light-gray2);
            opacity: 1 !important;

            .dark-theme & {
              color: #767992;
            }
          }

          &:-ms-input-placeholder {
            color: var(--light-gray2);
            opacity: 1 !important;

            .dark-theme & {
              color: #767992;
            }
          }

          &::-webkit-search-decoration {
            display: none;
          }

          &::-webkit-search-cancel-button {
            display: none;
          }

          &::-webkit-search-results-button {
            display: none;
          }

          &::-webkit-search-results-decoration {
            display: none;
          }

          @include bp(xxlg-max) {
            width: 27rem;
          }

          @include bp(xxs-max) {
            width: 100%;
          }
        }
      }
    }

    .table-actions-right {
      // @include bp(md-max) {
      //     margin-top: 1.5rem;
      //     width: 100%;
      //     order: 1;
      // }

      // @include bp(sm-max) {
      //   order: 1;
      //   width: 100%;
      //   text-align: right;
      // }

      button {
        font-size: 1.4rem;
        height: 4rem;
        line-height: 4rem;
        transition: all 0.5s;
        text-transform: uppercase;
        padding: 0.5rem 1.5rem;
        border-radius: 0.5rem;

        @include bp(xxs-max) {
          font-size: 1.2rem;
          // width: 100%;
        }

        &:focus {
          box-shadow: none !important;
        }

        &:not(:last-child) {
          margin-right: 1.5rem;

          @include bp(lg-max) {
            margin-right: 0.8rem;
          }

          // @include bp(smd-max) {
          //   margin-right: 1.2rem;
          // }
          @include bp(xxs-max) {
            margin-right: 1.5rem;
            // margin-bottom: 1.5rem;
          }
        }

        &.export-btn {
          background-color: var(--white);
          border-color: var(--light-gray1);
          color: var(--heading-text);

          .pi {
            color: var(--heading-text);
            margin-right: 1.2rem;
            transition: all 0.2s;

            @include bp(lg-max) {
              margin-right: 0.7rem;
            }

            @include bp(sm-max) {
              margin-right: 0.3rem;
              font-size: 1.4rem;
            }
          }

          &:hover {
            background-color: var(--secondary-btn-bg);
            // background-color: darken(#f4f4f4, 5);
            // background-color: var(--title-light-text);
          }

          .dark-theme & {
            color: var(--light-gray1);

            .pi {
              color: var(--light-gray1);
            }

            &:hover {
              background-color: var(--secondary-btn-bg);
              color: var(--surface-a);
              border-color: var(--secondary-btn-bg);

              .pi {
                color: var(--surface-a);
              }
            }
          }
        }

        &.add-user-btn {
          background-color: var(--primary-color);
          border-color: var(--btn-border-color);
          color: var(--primary-color-text);
          font-weight: 500;
          width: 4rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: var(--gradient1);
          color: var(--surface-a);
          box-shadow: 0 0.2rem 0.6rem rgba(115, 103, 240, 0.3) !important;
          border-radius: 50%;
          margin-right: 0;
          em{
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            img{
              min-width: 1.7rem;
            }
          }
          .dark-theme & {
            border-color: var(--link-hover-bg);

            &:hover {
              border-color: var(--link-hover-bg);
              background: var(--link-hover-bg);
            }
          }

          // &:hover {
          //   background-color: darken(#7367f0, 5);
          //   border-color: darken(#7367f0, 5);
          //   .pi {
          //     color: var(--surface-a);
          //   }
          // }
          .pi {
            color: var(--primary-color-text);
            margin-right: 0;
            height: 1.7rem;
            width: 1.7rem;
            font-size: 1.7rem;

            @include bp(sm-max) {
              // font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

// i.delete-button,
// i.edit-button,
// i.view-button {
//   display: none;
// }
// .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td i.pi.pi-fw.pi-pencil {
//   display: none;
// }

.popup_menu {
  &.action-popup {
    min-width: 14rem;
    max-width: 20rem;
    width: auto;
    border-radius: 0.6rem;
    padding: 1rem 0;

    .p-menuitem {
      padding: 0 0.8rem;

      .p-menuitem-link[disabled] {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }

      .p-menuitem-link {
        padding: 0.9rem 0.8rem 0.9rem 0.8rem;
        border-radius: 0;
        display: flex;
        align-items: center;
        border-radius: 0.6rem;
        transition: all 0.5s;

        .p-menuitem-icon {
          font-size: 1.6rem;
          margin-right: 1.1rem;
        }

        &:hover {
          color: var(--black1);
          background-color: var(--white2);

          .p-menuitem-icon {
            color: var(--black1);
          }

          .dark-theme & {
            color: var(--title-dark-text);
            background-color: var(--link-hover-bg);

            .p-menuitem-icon {
              color: var(--title-dark-text) !important;
            }
          }
        }
      }
    }
  }
}

.more-actions-wrapper {
  margin-left: 1rem;

  .p-button {
    &.primary-icon-btn {
      &.p-button-rounded {
        min-width: 30.2rem;
        height: 30.2rem;
        width: 30.2rem;

        .pi {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.__react_component_tooltip.place-top::before {
  display: none !important;
}
.horizontal-switch {
  display: flex;
  align-items: center;
  gap: 1rem;
  label {
    margin: 0 !important;
  }
}
table {
  .single-param,
  .grouped-params {
    padding: 0.5rem 0.8rem; 
      border-radius: 0.3rem;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      border: none; 
      font-family: 'Public Sans', sans-serif;
      height: auto;
      span {
        padding: 0;
      }
  }
  .single-param {
    background: #E7F4FF;
    color: #64B5F6;
    .dark-theme & {
      background: #0E2D47;
    }
  }
  .grouped-params {
    background: #F4EDFF;
    color: #9575CD;
    .dark-theme & {
      background: #361E5B;
    }
  }
}

.technology-list {
  margin: 0 -1.5rem -0.8rem 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include bp(lmd-max) {
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .technology-badge {
    padding: 0.5rem 0.8rem;
    background-color: transparent;
    border-radius: 0.3rem;
    margin: 0 0.4rem 0.6rem 0;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    border: none;
    color: var(--surface-a) !important;

    &.java-badge {
      /* done */
      background: #4dd0e1 !important;
    }

    &.net-badge {
      /* done */
      background: #7986cb !important;
    }

    &.ios-badge {
      background: #9575cd !important;
    }

    &.reactjs-badge {
      /* done */
      background: #4fc3f7 !important;
    }

    &.react-badge {
      /* done */
      background: #4fc3f7 !important;
    }

    &.node-badge {
      /*done*/
      background: #4db6ac !important;
    }

    &.nodejs-badge {
      /*done*/
      background: #4db6ac !important;
    }

    &.designing-badge {
      /*done*/
      background: #aed581 !important;
    }

    &.android-badge {
      /*done*/
      background: #90a4ae !important;
    }

    &.php-badge {
      /*done*/
      background: #ba68c8 !important;
    }

    &.laravel-badge {
      /*done*/
      background: #e57373 !important;
    }

    &.vuejs-badge {
      /*done*/
      background: #f06292 !important;
    }

    &.perl-badge {
      /*done*/
      background-color: #ffd54f !important;
    }

    &.angular-badge {
      /*done*/
      background-color: #81c784 !important;
    }

    &.python-badge {
      /*done*/
      background-color: #ffb74d !important;
    }

    &.go-badge {
      /*done*/
      background-color: #64b5f6 !important;
    }

    &.c-badge {
      background-color: #ff8a65 !important;
    }

    &.express-badge {
      background-color: #aed581 !important;
    }

    &.expressjs-badge {
      background-color: #aed581 !important;
    }
  }
}

.table-view-popup-link {
  &:hover {
    text-decoration: underline;
  }
}

.experience-filter-dropdown {
  .MuiPaper-root {
    box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1) !important;
    .dark-theme & {
      background-color: var(--dark-boxes);
      box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.25) !important;
      color: var(--title-dark-text);
    }
  }

  .MuiInputBase-root {
    input {
      border-radius: var(--border-radius);
      border: 0.2rem solid var(--light-gray);
      height: 2rem;
      color: var(--black2);
      font-size: 1.4rem;
      .dark-theme & {
        border-color: #2c2a40;
        color: #e4e6f4;
      }

      &:hover {
        border-color: #6366f1;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }
  .p-button.border-btn {
    .dark-theme & {
      color: #e4e6f4;
      border: 0.2rem solid #2c2a40;
      &:hover {
        border: 0.2rem solid var(--secondary-btn-bg);
      }
    }
  }
}
