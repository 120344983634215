.p-confirm-dialog {
    border-radius: 1.5rem !important;
    background-color: var(--surface-a);
    margin: 0;
    padding: 0;
    max-height: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0 5rem 10rem -2rem, rgba(0, 0, 0, 0.3) 0 3rem 6rem -3rem !important;

    .p-dialog-header {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        @include bp(smd-max){
            padding: 1.5rem;
        }
        .p-dialog-title {
            font-size: 1.8rem;
            line-height: 1.22;
            font-family: "Public Sans", sans-serif;
            font-weight: 600;
            color: var(--heading-text);
        }

        .p-dialog-header-icons {
            .p-dialog-header-close {
                height: 4rem;
                width: 4rem;
                margin: 0;
                background: transparent !important;
                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                // justify-content: flex-end;
                &:hover{
                    background: var(--rgba1) !important;
                }
                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
        }
    }

    .p-dialog-content {
        @include bp(smd-max){
            padding: 0.3rem 1.5rem 1.5rem;
        }
        .p-confirm-dialog-icon {
            display: none;
        }

        .p-confirm-dialog-message {
            font-size: 1.4rem;
            line-height: 1.6;
            margin-left: 0;
            margin-bottom: 0;
        }
    }
    .p-dialog-footer{
        border-top: 0.1rem solid var(--light-gray4);
        background-color: transparent;
        padding: 1.5rem 2.4rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        @include bp(smd-max){
            padding: 1.5rem;
        }
        button{
            span{
                text-transform: uppercase;
                font-weight: 400;
            }
            &.p-confirm-dialog-reject{
                border: 0.1rem solid var(--light-gray1);
                span{
                    color: var(--heading-text);
                }
                &:hover{
                    border-color: var(--light-gray1);
                    background-color: transparent;
                }
            }
        }
    }
}
.apply-job-modal{
    &.p-dialog {
        .p-dialog-header {
            .p-dialog-header-icon{
                &:last-child{
                    width: 4rem;
                    height: 4rem;
                    svg{
                        width: 1.6rem;
                        height: 1.6rem;
                    }                
                }                
            }
        }
        .profile-info-wrapper{
            .form-row-wrapper {
                .form-col{
                    &.form-col-3{
                        @include bp(sm-max){
                            max-width: 50%;
                            flex:0 0 50%;
                        }
                        @include bp(xs-max){
                            max-width: 100%;
                            flex:0 0 100%;
                        }
                    }
                }
            }
        }
    }
}