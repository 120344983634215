.tatvasoft-inner-page-banner{
    min-height: 460px;
    box-sizing: border-box;
    padding: 10rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    @include bp(sm-max){
        min-height: 360px;
    }
    .tatvasoft-inner-banner{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    }
    .banner-content-block{
        color: #fff;
        text-align: center;
        h1{
            text-transform: uppercase;
            margin: 0 0 1rem;
            text-shadow: 0 0 15px rgba(0, 0, 0, .7);
            line-height: 1.4;
            @include bp(llg-max){
                font-size: 5rem;
            }
            @include bp(lg-max){
                font-size: 4rem;
            }
            @include bp(md-max){
                font-size: 3.6rem;
            }
            @include bp(sm-max){
                font-size: 3.2rem;
            }
        }
    }
}
.career-detail-page-content{
    padding: 50px 0;
    @include bp(lg-max){
        padding: 40px 0;
    }
    .career-detail-description{
        padding-bottom: 40px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--gray-200);
        p{
            + p{
                margin-top: 25px;
            }
        }
    }
 
    p{
        font-size: 18px;
        a{
            font-size: inherit;
            color: var(--secondary-color);
            font-weight: 600;
            font-family: inherit;
            text-decoration: underline;
            display: inline;
        }
    }
    .btn-wrapper {
        margin-top: 30px;
        .btn{
            &:hover{
                background-color: var(--secondary-color);
                color: var(--surface-a);
                border-color:var(--secondary-color);
            }          
        }
    }
}
.job-detail-page{
  strong{
    font-size: 24px;
    line-height: 1.3;
    color: var(--secondary-color) !important;
    margin: 20px 0 !important; 
    font-weight: 400 !important;
    display: inline-block;
  }
  ul{
   margin: 0 !important;    
   list-style-type: none !important;
   padding: 0 !important;
   + ul{
    li{
        margin-top: 20px !important;
    }
   }
   li{
    position: relative;
    color: var(--heading-text);
    padding-left: 40px !important;
    box-sizing: border-box;
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 18px;                
    &::before{
        position: absolute;
        content: "";
        left: 20px;
        top: 10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: var(--content-block-dot-color);
    }
   }
  }
  label{
    font-size: 18px !important;
  }
  span{
    font-size: 18px !important;
  }
  .styles_other-details__oEN4O{
    margin-bottom: 30px !important;
    div{
        + div{
            margin-top: 20px !important;
        }
    }
  }
}